<template>
    
    <div>
        <ConfigutionCard />
    </div>

</template>


<script>
import ConfigutionCard from "../../components/dialogs/ConfigutionCard.vue";

export default {
    name: "ConfigAuthPage",
    components: {
        ConfigutionCard
    }
}
</script>
