import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import LoginPage from "../views/login/LoginPage.vue";
import EntityPage from "../views/entities/EntityPage.vue";
import EntityFormPage from "../views/entities/EntityFormPage.vue";
import ActionResultFullscreen from "../views/ActionResultFullscreen.vue";
import ConfirmAuthPage from "../views/confirmAuth/ConfirmAuthPage.vue";
import UserByGroup from "../views/user/UserByGroup.vue";
import GruposPage from '../views/user/UserPage.vue';
import UserGroupPage from '../views/user/UserGroupPage.vue';
import ConfigAuthPage from "../views/confirmAuth/ConfigAuthPage.vue";
import PerfilUser from "../views/Perfil/PerfilUser.vue";
import UpdatePasswordPage from "../views/Perfil/UpdatePasswordPage"
import AccountProfilePage from "../views/accountProfile/accountProfilePage.vue";
import firstAccessPage from "../views/firstAccess/firstAccessPage.vue";
import PivoTableView from "../views/pivoTable/PivoTableView.vue";
import CustomizationPage from '../views/customization/CustomizationPage.vue'
import SearchQR from '@/views/searchQR/SearchQR'
import ChangePassword from "@/views/changePassword/ChangePassword.vue";
import WeeklyScheduleView from "@/views/weeklyschedule/WeeklyScheduleView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
  },

  {
    path: "/home",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/entity/:schemaName",
        name: "Entity",
        component: EntityPage,
        props: true,
      },
      {
        path: "/entity/form/:schemaName/:id?",
        name: "EntityForm",
        component: EntityFormPage,
        props: true,
      },
      {
        path: "/action/result",
        name: "ActionResult",
        component: ActionResultFullscreen,
      },

      {
        path: "/s",
        name: "SearchQR",
        component: SearchQR,
      },

      {
        path: "/userPage",
        name: "UserPage",
        component: UserByGroup,
      },

      {
        path: "/accountProfile",
        name: "AccountProfilePage",
        component: AccountProfilePage,
      },
/*
      {
        path: "/accountProfileList",
        name: "AccountProfileList",
        component: AccountProfileList
      },*/

      {
        path: "/configAuth/",
        name: "ConfigAuthPage",
        component: ConfigAuthPage,
      },
      {
        path: "/users/:groupName/:id",
        name: "UserGroupPage",
        component: UserGroupPage,
      },

      {
        path: "/grupos",
        name: "userGroups",
        component: GruposPage
      },

      {
        path: "/perfilUser",
        name: "PerfilUserPage",
        component: PerfilUser,
      },

      {
        path: "/updatePassword",
        name: "UpdatePasswordPage",
        component: UpdatePasswordPage,
      },

      {
        path: "/pivoTable/:viewId/:viewName",
        name: "PivoTableView",
        component: PivoTableView,
        props: true
      },

      {
        path: "/customization",
        name: "Customization",
        component: CustomizationPage
      },

      {
        path: "/weeklySchedule",
        name: "WeeklySchedule",
        component: WeeklyScheduleView
      }

    ],
  },
  {
    path: "/confirmAuth",
    name: "ConfirmAuthPage",
    component: ConfirmAuthPage,
  },
  {
    path: "/firstAccess",
    name: "firstAccess",
    component: firstAccessPage,
  },

  {
    path: "/changePassword",
    name: "ChangePassword",
    component: ChangePassword
  }

];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let token = await Vue.prototype.$auth.getUserToken();
  //if (from.name !== "Login") {

    if(to.name == "SearchQR" && !token){

      let href = location.href?.split('/s');
      let s = href[1].split('/#/')
      let newHref = `${href[0]}/#/s${s[0].replace('#/', '')}`

      location.href = newHref
      let url = new URLSearchParams(s[0].replace('?', ''))

      next({
        name: "Login",
        query: { s: 's', v: url.get('v'), t: url.get('t') }
      });
    }

    else if(to.name == "ChangePassword" && !token){
      next()
    }

    else if (to.name !== "Login" && !token) {
      Vue.prototype.$notfy.error(
        "Sua sessão expirou, faça login novamente"
      );
      //window.setTimeout(() => {
        next({
          name: "Login",
        });
      //}, 1000);
    } else {
      if (to.name === "Login" && token) {
        next({
          name: "Home",
        });
      } else {
        next();
      }
    }
  //} else {
  //  next();
  //}
});

export default router;
