<template>
	<v-runtime-template :template="result.view"></v-runtime-template>
</template>

<script src="https://unpkg.com/write-excel-file@1.x/bundle/write-excel-file.min.js"></script>

<script>
	//import tableExport from "tableexport";
	//import fileSaver from "file-saverjs";

	//import { createFromObjectArray } from "styled-xls";
	//import { saveAs } from "file-saver";
	//import xlsx from "json-as-xlsx";
	//import VRuntimeTemplate from "v-runtime-template";
	//import * as VuetifyComponents from "vuetify/lib/components";
	import xlsx from "json-as-xlsx";

	export default {
		name: "ActionXLSX",

		props: {
			result: Object, 
		},

		data() {
			return {};
		},
		methods: {
			// download() {
			// 	const Excel = require("exceljs");
			// 	var data;
			// 	async () => {
			// 		const url = "https://evolutionsoft.dev.br/teste.xlsx";
			// 		data = await (await fetch(url)).arrayBuffer();
			// 	};

			// 	const workbook = new Excel.Workbook();
			// 	workbook.xlsx
			// 		.readFile(data)
			// 		.then((stream) => {
			// 			const options = {
			// 				useSharedStrings: true,
			// 				useStyles: true,
			// 			};

			// 			return stream.xlsx
			// 				.writeFile("./out/template-out.xlsx", options)
			// 				.then(() => {
			// 					console.log("Done.");
			// 				});
			// 		})
			// 		.catch((error) => {
			// 			console.error(error.message);
			// 			console.error(error.stack);
			// 		});
			// },

			downloadExcel() {
				let data = [
						{
							sheet: "Adults",
							columns: [
							{ label: "User", value: "user" }, // Top level data
							{ label: "Age", value: (row) => row.age + " years" }, // Custom format
							{ label: "Phone", value: (row) => (row.more ? row.more.phone || "" : "") }, // Run functions
							],
							content: [
							{ user: "Andrea", age: 20, more: { phone: "11111111" } },
							{ user: "Luis", age: 21, more: { phone: "12345678" } },
							],
						},
						{
							sheet: "Children",
							columns: [
							{ label: "User", value: "user" }, // Top level data
							{ label: "Age", value: "age", format: '# "years"' }, // Column format
							{ label: "Phone", value: "user.more.phone", format: "(###) ###-####" }, // Deep props and column format
							],
							content: [
							{ user: "Manuel", age: 16, more: { phone: 9999999900 } },
							{ user: "Ana", age: 17, more: { phone: 8765432135 } },
							],
						},
						]

						let settings = {
						fileName: "MySpreadsheet", // Name of the resulting spreadsheet
						extraLength: 3, // A bigger number means that columns will be wider
						writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
						}

						xlsx(data, settings)
            },

			// downloadExcel() {
			// 	console.log("entrou");
			// 	// XLSX is a global from the standalone script
			// 	var xlsx = require("xlsx");
			// 	(async () => {
			// 		console.log("entrou");
			// 		const url = "https://evolutionsoft.dev.br/teste.xlsx";
			// 		const data = await (await fetch(url)).arrayBuffer();
			// 		/* data is an ArrayBuffer */
			// 		const workbook = XLSX.read(data);
			// 		console.log("entrou");
			// 		var ws = workbook.Sheets["folha1"];
			// 		var json = xlsx.utils.sheet_to_json(ws);
			// 		console.log(json);
			// 		var newData = json.map(function(record) {
			// 			record = "";
			// 			console.log(record);
			// 			return record;
			// 		});
			// 		var newWB = xlsx.utils.book_new();
			// 		var newWS = xlsx.utils.json_to_sheet(newData);
			// 		//xlsx.utils.book_update_sheet(workbook, newWS);
			// 		xlsx.utils.book_append_sheet(newWB, newWS, "folha221");

			// 		//xlsx.writeFile(workbook, "kkk.xls").download();
			// 		xlsx.writeFile(newWB, "New Data Teste.xlsx").download();
			// 		/* DO SOMETHING WITH workbook HERE */
			// 	})();
			// 	// var wb = xlsx.readFile("https://evolutionsoft.dev.br/teste.xlsx");
			// },
		},

		mounted() {
			this.downloadExcel();
		},
	};
</script>
