import RepoServise from "./repoService";

class EntityService extends RepoServise {
  constructor(schema) {
    super(`Entity/${schema}`);
  }

  async searchAutoComplete(searchFields, value, companySiteId) {
    return await this.search(searchFields, value, 1, 10, null, "Contains", companySiteId);
  }

  async autoInc(schemaName, fieldInc, mask){
    return await this.http.get(`Entity/AutoInc`, { params: { schemaName: schemaName, field: fieldInc, mask: mask }})
    //this.http.get(`Entity/AutoInc?schemaName=${schemaName}&field=${fieldInc}&mask=${mask}`)
  }
  
}

export default EntityService;
