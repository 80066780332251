import entityFormDialog from "../components/dialogs/entityFormDialog.vue";
import EntityService from "../services/entityService.js";
import schemaService from "../services/schemaService.js";
import { getSchemaActions } from "../services/actionService";
import { getSearchDefsForSchema, createSearchDef, updateSearchDef, deleteSearchDef } from "../services/searchDefsService";
import moment from 'moment';
import { FILTER_OPERATORS } from '../utils/filterOperators.js';
import entityFormMixin from './entityFormMixin.js'

import emitter from "@/services/emitter";

export default {
  props: ["schemaName", "ids", "relation", "item"],
  components: { entityFormDialog },
  mixins: [entityFormMixin],
  data() {
    return {
      companySiteId: null,
      entityService: new EntityService(this.schemaName),
      key: 0,
      schema: null,
      items: [],
      customActions: [],
      advancedSearchMode: false,
      searchDefs: [],
      selectedSearchDef: {
        schemaName: this.schemaName,
        description: null,
        id: null,
        userId: null,
        defs: {
          userColumns: [],
          filters: [],
          orderBy: [],
        }
      },
      isSave: true,
      saved: true,
      loading: true,
      headers: [],
      search: "",
      mode: 'Contains',
      formDialog: false,
      isDelete: false,
      actions: [
        {
          icon: "mdi-pencil",
          color: "warning",
          title: "Visualizar/Editar",
          handle: () => {
            this.editItem();
          },
        },
        {
          icon: "mdi-delete",
          color: "error",
          title: "Deletar",
          handle: (typeDelte) => {
            if(typeDelte == 'deleteMany'){
              this.itemsForDelete();
            } else {
              this.deleteItem();
            }
          },
        },
      ],
      idsItemSelectedDelete: [],
      itemSelected: {},
      rowMenu: {
        showMenu: false,
        closeOnContentClick: false,
        x: 0,
        y: 0,
        menuX: 0,
        menuY: 0,
      },
      rowMenuOpen: false,
      pagination: {
        page: 1,
        limit: 30,
        pages: 1,
      },
    };
  },
  watch: {

    search(){
      this.mode = 'Contains'
    },

    async mode(){
      await this.getItems();
/*
      if(n.length < a.length){
        this.mode = 'Contains'
      }*/

      
    },

    async companySiteId(){
      if(this.schema.multi_company) await this.getItems();
    },

    async $route (to){
      let id = to.query.id
      let schemaName = window.location.href.split('entity/')[1]?.split('?')[0]
      if(this.schema.title == schemaName && id != undefined){
        let object = await this.entityService.search(undefined, undefined, 1, 1, id)
        this.openItemUrl(object.data.data.data[0])
      }
    },

    async ids(value) {
      if (!value || value.length == 0) this.items = [];
      else await this.getItems();
    },
    async schemaName() {
      this.selectedSearchDef = null;
      this.advancedSearchMode = false;
      this.$set(this, "searchDefs", []);
      this.entityService = new EntityService(this.schemaName);
      await this.getSchema();
      await this.getItems();
      await this.getActions();
      await this.getSearchDefs();
    },
    selectedSearchDef () {
      //console.log('b')
      this.getItems()
    },
    advancedSearchMode () {
      //console.log('Value ', value)
    /*  if (value && this.searchDefs.length) {
        this.$set(this, "selectedSearchDef", this.searchDefs[0]);
      } else {
        this.$set(this, "selectedSearchDef", null);
      }*/
    }
  },
  computed: {
    headersComp() {
      if (!this.schema) return [];

      let form = this.schema.form.properties;

      let properties = this.schema.properties;
      let propertiesNames = Object.getOwnPropertyNames(properties);
      //var fieldsTable = this.schema.fieldsTable


      let maps = propertiesNames
        .filter((pn) => (properties[pn].relationSchema == null //|| properties[pn].viewGrid 
          ) && form[pn])
        .map((p) => {
          let prop = form[p];
         return { text: prop.label, value: p };
        });

        if(this.schema.propertiesVisibleTable){
          let propertiesVisibleTable = this.schema.propertiesVisibleTable.split(',')
          maps.sort((a, b) => {
            
            let valueA = propertiesVisibleTable.indexOf(a.value);
            let valueB = propertiesVisibleTable.indexOf(b.value);

            if (valueA > valueB) return 1;
            else if (valueA < valueB) return -1;
            else return 0;


          });
        } else {
          maps.splice(maps.length - 1);
        }


      
      //maps = maps.filter(m => m !== undefined)
      return maps;
    },

    filteredItems () {
      if (!this.items) return [];
      if (!this.selectedSearchDef?.def?.length) return this.items;

      const filters = this.selectedSearchDef.def

      let items = this.items.filter((item) => {
        let valid = true

        filters.forEach((filter) => {
          valid = valid && FILTER_OPERATORS[filter.operator].handler(item[filter.field], filter.value)
        })

        return valid
      })

      return items
    }
  },
  methods: {
    async saveItem(item, position) {
      try {
        this.loading = false
        if (item) this.itemSelected = Object.assign({}, item);

        let requireds = Object.entries(this.schema.form.properties).filter(resp => resp[1]?.required == true)
        if(requireds.length > 0){
          for(let i = 0; i < requireds.length; i++){
            if(!this.itemSelected[requireds[i][0]] || this.itemSelected[requireds[i]] == ""){
              this.$notfy.error(`Campo requerido ${requireds[i][1].label}`)
              this.loading = true
              return
            }
          }
        }

        // await this.deleteManyItems(this.idsItemSelectedDelete, true)
        let res = await this.entityService.insertOrUpdate(this.itemSelected, position);
        if (!res.data.success){
          this.loading = true
          return;
        } 

        if(this.itemSelected.id) {
          localStorage.removeItem(`${this.schema.title}-${this.itemSelected.id}`)
        } else {
          localStorage.removeItem(`${this.schema.title}`)
        }

        this.isSave = true;
        this.formDialog = false;
        await this.saveIdsInProps(res);
        await this.clearItemSelected();
        await this.getItems();
        this.$emit("update-item");
        this.loading = true 
      } catch(err){
        //
      }
    },

    async saveIdsInProps(res) {
      if (this.relation != null && !this.itemSelected.id) {
        let idsUpdate = [];
        if (this.ids && this.ids.length > 0) idsUpdate = Array.from(this.ids);

        idsUpdate.push(res.data.data.id);
        await this.$emit("input-ids", idsUpdate);
      }
    },
    async deleteIdInProps(item) {
      if (this.relation != null) {
        let idsUpdate = Array.from(this.ids);
        if (Array.isArray(item)) {
          item.forEach((i) => {
            let itemDeleted = idsUpdate.find((id) => id == i.id);
            let index = idsUpdate.indexOf(itemDeleted);
            idsUpdate.splice(index, 1);
          });

          await this.$emit("input-ids", idsUpdate);
          return;
        }

        if(item){
          let itemDeleted = idsUpdate.find((id) => id == item.id);
          let index = idsUpdate.indexOf(itemDeleted);
          idsUpdate.splice(index, 1);
        }

        await this.$emit("input-ids", idsUpdate);
      }
    },

    async deleteSubItems(){
      let properties = Object.assign(this.schema.properties)

      let propertiesRelationSchema = Object.entries(properties).filter(resp => resp[1].type == 'array' 
        && resp[1].relationSchema && !resp[1].multiple)

        let itemSearch
        if(this.itemSelected.id){
          itemSearch = await this.entityService.search(null, null, 1, 1, this.itemSelected.id)
        }
     
      propertiesRelationSchema.map(async res => {
        let entity = new EntityService(res[1].relationSchema)


        if(this.itemSelected[res[0]] !== undefined){ 
          this.itemSelected[res[0]]?.forEach(async element => {

            try {

              //let searchRelationEntity = await entity.getById(element)
              //if(searchRelationEntity?.data?.data?.data[0]?.id){

                if(itemSearch?.data?.data?.data[0]?.id){
                  if(itemSearch.data.data.data[0][res[0]]?.indexOf(element) === -1){
                    await entity.delete(element)
                  } if(this.isDelete){
                    await entity.delete(element)
                  }
                } else {
                  await entity.delete(element)
                }
            //} 
            } catch(err){

              //
            }
          })
        }
      })
    },


    async cancelFormDialog() {
      if (await this.$isConfirm()) this.formDialog = false;

      if(this.formDialog == false){

        //this.deleteSubItems()

        if(this.itemSelected.id) {
          localStorage.removeItem(`${this.schema.title}-${this.itemSelected.id}`)
        } else {
          localStorage.removeItem(`${this.schema.title}`)
        }
        this.isSave = false;

      }

    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    updateMouseCoordinates(event) {
      this.rowMenu.x = event.clientX;
      this.rowMenu.y = event.clientY;
    },
    async openRowMenu() {
      await this.timeout(100);
      this.rowMenu.menuX = this.rowMenu.x;
      this.rowMenu.menuY = this.rowMenu.y;

      this.$nextTick(() => {
        this.rowMenu.showMenu = true;
      });
    },
    async rowClick(row) {
      this.itemSelected = Object.assign({}, row);
      //let req = await this.entityService.search(null, null, 1, 1, this.itemSelected.id)
      //this.itemSelected = req.data.data.data[0]
      await this.openRowMenu();
    },
    async changePage() {
      await this.getItems();
      window.scrollTo(0, 0);
    },
    async getSchema() {
      let res = await schemaService.getByName(this.schemaName);
      if (res?.data?.success) {
        this.schema = JSON.parse(res.data.data.jsonValue);
      }
    },
    createHeaders() {},
    async searchItems() {
      //var delayTimer;
      //var doneTypingInterval = 2000;
      this.pagination.page = 1;

      if(!this.advancedSearchMode){
        //clearTimeout(delayTimer);

        //delayTimer = setTimeout(async () => {
            await this.getItems();
          //}, doneTypingInterval)
        
      } else {
        await this._getItemsSearchDef();
      }
      
    },
    newItem() {
      this.itemSelected = {};
      this.formDialog = true;

      let entityLocal
      if(this.schema.title != 'Weeklyschedule'){
        entityLocal = localStorage.getItem(`${this.schema.title}`)
      } 
      if(this.schema.title != 'NoteWeeklySchedule'){
        entityLocal = localStorage.getItem(`${this.schema.title}`)
      }
      if(entityLocal && !JSON.parse(entityLocal)?.id){
        this.itemSelected = JSON.parse(entityLocal)
      }
    },
    async editItem() {
      //if (item) this.itemSelected = Object.assign({}, item);
      //let req = await this.entityService.search(null, null, 1, 1, this.itemSelected.id)
      let req = await this.entityService.getById(this.itemSelected.id)
      this.itemSelected = req?.data?.data

      let entityLocal
      if(this.schema.title != 'Weeklyschedule'){
        entityLocal = localStorage.getItem(`${this.schema.title}-${this.itemSelected.id}`)
      }
      if(this.schema.title != 'NoteWeeklySchedule'){
        entityLocal = localStorage.getItem(`${this.schema.title}-${this.itemSelected.id}`)
      }
      if(this.itemSelected.id == JSON.parse(entityLocal)?.id){
        this.itemSelected = JSON.parse(entityLocal)
      }


      this.formDialog = true;
    },

    openItemUrl(item){
      this.itemSelected = item
      this.formDialog = true
    },
    async deleteItem(item, notConfirm) {
      if (!notConfirm) if (!(await this.$isConfirm())) return;
      if (item) this.itemSelected = Object.assign({}, item);

      let req = await this.$notificationService.verifyNotification(this.itemSelected.id)

      let userLogin = localStorage.getItem('user-login')
      userLogin = JSON.parse(userLogin)

      let aproveUser = null
      let verifyNotification = req?.data?.data
      if(verifyNotification != null){
        let user = verifyNotification?.aprovEmail.filter(resp => resp.email == userLogin[1])
        if(user?.length > 0){
          aproveUser = user[0].aprov
        } else {
          aproveUser = false
        }
        } /*else {
          verifyNotification = true
        }*/

      if(verifyNotification == null && aproveUser == null){
        this.isDelete = true
        //this.deleteSubItems();
        let res = await this.entityService.delete(this.itemSelected.id);
        if (!res.data.success) return;
        await this.deleteIdInProps(this.itemSelected);
        if (this.formDialog) this.formDialog = false;
        this.clearItemSelected();
        await this.getItems();
        this.$emit("update-item");
      } else {
        this.$notfy.error('Aguardando aprovação')
      }
    },
    async deleteManyItems(items, notConfirm) {
      if (!notConfirm) if (!(await this.$isConfirm())) return;
      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        let res = await this.entityService.delete(item.id);
        if (!res.data.success) return;
        if (this.formDialog) this.formDialog = false;
        this.clearItemSelected();
      }
      await this.deleteIdInProps(items);
      this.$emit("update-item");
      await this.getItems();
    },
    async itemsForDelete(){
      if (!(await this.$isConfirm())) return;
      await this.deleteIdInProps(this.itemSelected)
      this.idsItemSelectedDelete.push(this.itemSelected.id)
    },
    clearItemSelected() {
      this.itemSelected = {};
    },
    async getItems() {
      if (!this.advancedSearchMode) this._getItems();
      else this._getItemsSearchDef();
    },

    getFomats(){
      const properties = this.tabGeneralComp.properties
      var propertiesData = properties.filter(resp => 
        resp.schema.type === 'date'
      )
 
      var propertiesDataTime = properties.filter(resp => 
         resp.schema.type === 'datetime-local'
      )
 
      var propertyMoney = properties.filter(resp => 
         resp.schema.type == 'number' && resp.schema?.real == true)
     
       var propertyBoolean = properties.filter(resp =>
         resp.schema.type === 'boolean'
       )

       var propertyRelationSchema = properties.filter(resp => 
          resp.schema.viewGrid == true && resp.schema.relationSchema
       )

       var propertyFile = properties.filter(resp => 
          resp.schema.file
        )

       var propertyTypeUser = properties.filter(resp => 
        resp.schema.type == 'user'
      )

      var propertyOwner = properties.filter(resp => 
        resp.schema.type == 'array' && resp.schema.owner == true
      )

 
    
       this.tabsComp.map(tabs => {
         tabs.properties.map(resp => {
           if(resp.schema.type == 'number' && resp.schema?.real) propertyMoney.push(resp)
 
           if( resp.schema.type === 'date') propertiesData.push(resp)
 
           if(resp.schema.type === 'datetime-local') propertiesDataTime.push(resp)
 
           if(resp.schema.type === 'boolean') propertyBoolean.push(resp)

           if(resp.schema.type === 'user') propertyTypeUser.push(resp)

           if( resp.schema.type == 'array' && resp.schema.owner == true) propertyOwner.push(resp)
         })
       })

       this.items?.map(async resp => {

        
        for(let i = 0; i < propertyTypeUser.length; i++){

            if(resp[propertyTypeUser[i].name]?.name !== undefined){
              resp[propertyTypeUser[i].name] = `${resp[propertyTypeUser[i].name]?.name}`
            }

        }

        for(let i = 0; i < propertyOwner.length; i++){

          if(resp[propertyOwner[i].name] !== undefined || resp[propertyOwner[i].name].length > 0){
            //console.log('RESP >>> ', resp[propertyOwner[i]])
            //resp[propertyOwner[i].name] = `${resp[propertyOwner[i].name][0].userName}`
            resp[propertyOwner[i].name] = resp[propertyOwner[i].name].map(user => {
              return user.userName
            }).join(', ')
          }

        }

        for(let i = 0; i < propertyFile.length; i++){
          if(resp[propertyFile[i].name] !== undefined){
/*
            let items = resp[propertyFile[i].name]
            resp[propertyFile[i].name] = items.map((item) => {
              let path = item.split('/')
              return path[path?.length - 1]
            }).join(' - ')

           */ 
          }
        }

        for(let i = 0; i < propertiesData.length; i++){
          
            if(resp[propertiesData[i].name] === '0000-00-00' || resp[propertiesData[i].name] == "" 
              || resp[propertiesData[i].name] == null){
              resp[propertiesData[i].name] = ''
            }
            else {
              if(resp[propertiesData[i].name] !== undefined){

                //resp[propertiesData[i].name] = moment(resp[propertiesData[i].name]).format("DD/MM/YYYY")
                resp[propertiesData[i].name] = resp[propertiesData[i].name].split('T')[0].split('-').reverse().join('/')
              } 

            }
          }

          for(let i = 0; i < propertiesDataTime.length; i++){

            if(resp[propertiesDataTime[i]?.name] == undefined){
              resp[propertiesDataTime[i].name] = ''
            } else {
             resp[propertiesDataTime[i].name] = moment(resp[propertiesDataTime[i].name]).format('DD/MM/YYYY HH:mm');
            }

          }

          for(let i = 0; i < propertyMoney.length; i++){
            resp[propertyMoney[i]?.name] = resp[propertyMoney[i]?.name]?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
          }

          for(let i = 0; i < propertyBoolean.length; i++){
            if(propertyBoolean[i].schema?.msg){
              if(resp[propertyBoolean[i].name] === true){
                resp[propertyBoolean[i].name] = propertyBoolean[i].schema?.msg.true
              } else {
                resp[propertyBoolean[i].name] = propertyBoolean[i].schema?.msg.false
              }
            }
          }

          for(let i = 0; i < propertyRelationSchema.length; i++){
            if(resp[propertyRelationSchema[i].name] !== undefined){
              let entity = new EntityService(propertyRelationSchema[i].schema.relationSchema)
              let item = await entity.search(null, null, 1, 1, resp[propertyRelationSchema[i].name])
              resp[propertyRelationSchema[i].name] = item.data.data.data[0].description
            }
            
          }
        })


    },

    async _getItems() {

      let newIds = []

    if(this.ids?.length > 0){
      newIds = this.ids?.filter(resp => resp !== null)
    }


      if (this.relation != null && (!this.ids || this.ids?.length == 0 /*|| !this.ids[0]*/)) return;

      if (this.schema?.searchFields === undefined) {
        console.log(
          "ATTENTION: if you are trying to search something, make sure that your schema have searchFields property"
        );
      }

      let res


      const data = await this.$auth.getUserParams()
      let companySiteId = null
      if(this.companySiteId == null && this.schema.multi_company){
        companySiteId = data.companySiteIdDefault
      } else if(this.companySiteId != null && this.schema.multi_company) {
        companySiteId = this.companySiteId
      } else {
        companySiteId = ''
      }


      if (this.schema.principal == true /*|| window.location.href.split('/accountProfile').length > 0*/) {
        res = await this.entityService.getAll(
          this.pagination.page,
          this.pagination.limit,
          this.search,
          this.schema.searchFields,
          newIds ? newIds : undefined, //this.ids,
          this.mode,
          companySiteId
        );
      } 
      else {
        if(newIds?.length < 99){
          res = await this.entityService.getAll(
            this.pagination.page,
            this.pagination.limit,
            this.search,
            this.schema.searchFields,
            newIds ? newIds : undefined, //this.ids,
            this.mode,
            companySiteId
          );
        } else {

          let schemaName = window.location.href.split('entity/')[1]?.split('?')[0]
          let relationSchema = this.schemaName

          res = await this.entityService.getAllSearchItems(
            this.pagination.page,
            this.pagination.limit,
            schemaName,
            relationSchema,
            this.item.id,
            this.search,
            this.schema.searchFields
          );
        }
      }

      if (res.data.success) {
        this.items = res?.data?.data?.data;
        this.getFomats()


        this.pagination.pages = res?.data?.data?.pages;
        this.key += 1
      }
    },

    async _getItemsSearchDef() {
      if (this.relation != null && (!this.ids || this.ids.length == 0)) return;
/*
      if (!this.selectedSearchDef?.id) {
        this._getItems();
        return
      }*/

      if(this.selectedSearchDef){
      let res = await this.entityService.getAllBySearchDef(
        this.pagination.page,
        this.pagination.limit,
        this.selectedSearchDef,
        this.search,
        this.schema?.searchFields
      );
   
      if (res?.data?.success) {
        this.items = res?.data?.data?.data;
        
        this.getFomats()
        
        this.pagination.pages = res.data?.data?.pages;
      }
    }
    },
    async getActions() {
      this.customActions = await getSchemaActions(this.schemaName);
    },
    async getSearchDefs() {
      const searchDefs = await getSearchDefsForSchema(this.schemaName);

      searchDefs.forEach(searchDef => {
        searchDef?.defs?.orderBy?.forEach(orderBy => {
          orderBy.text = this.getFieldLabel(orderBy.field) + ` (${orderBy.direction})`;
        })
      })

      this.$set(this, "searchDefs", searchDefs);
      if (!this.searchDefs.length) {
        const userData = await this.$auth.getUserParams();
        const searchDef = {
          schemaName: this.schemaName,
          userId: userData.userId,
          description: 'Padrão',
          def: {
            userColumns: [this.schema.descriptor],
            orderBy: [],
            filters: []
          },
        }
        this.createSearchDef(searchDef)
      }
    },

    async createSearchDef (searchDef) {
      const response = await createSearchDef(searchDef)
      this.getSearchDefs();
      return response
    },
    async deleteSearchDef (searchDefId) {
      const response = await deleteSearchDef(searchDefId)
      this.getSearchDefs();
      return response
    },
    updateSearchDef (searchDef) {
      return updateSearchDef(searchDef)
    },

    getFieldLabel (fieldName) {
      return this?.schema?.form?.properties?.[fieldName]?.label || fieldName
    },

  },
  async mounted() {
    if (this.relation != null && !this.ids) await this.$emit("input-ids", []);

    await this.getSchema();
    await this.getItems();
    await this.getActions();
    
    if(!this.formDialog) await this.getSearchDefs();

    let schemaName = window.location.href.split('entity/')[1]?.split('?')[0]
    let id = window.location.href?.split('?id=')[1]
    if(this.schema.title == schemaName && id != undefined){
      let object = await this.entityService.search(undefined, undefined, 1, 1, id)
      this.openItemUrl(object.data.data.data[0])
    }

    await emitter.on('eventCompanyId', async (companyId) => {
      this.companySiteId = companyId
    })
  },
};
