<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid class="pa-0 ma-0 container">
        <v-row class="justify-center items-center">
          <v-col align-center md="6" sm="12" class="area-access">
            <div class="login-container">
              <h2>Área de Acesso</h2>
              <p>
                Bem vindo! Insira suas informações de acesso ou crie uma nova
                conta.
              </p>
            
            <v-form v-on:submit.prevent="sendLogin()" class="mt-5">
              <v-col cols="12" align="center" class="pa-0">
              <v-text-field
                prepend-inner-icon="mdi-account-box"
                name="login"
                label="Tenanty"
                type="text"
                v-model="model.tenanty"
                outlined
                @input="getTenanty()"
                @keyup.enter.native="sendLogin"
                height="45px"
              ></v-text-field>
              <v-text-field
                density="compact"
                prepend-inner-icon="mdi-account"
                name="login"
                label="Email"
                type="text"
                outlined
                v-model="model.email"
                @keyup.enter.native="sendLogin"
                height="45px"
              ></v-text-field>
              <v-text-field
                density="compact"
                id="password"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                name="password"
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                outlined
                v-model="model.password"
                @keyup.enter.native="sendLogin"
                height="45px"
              ></v-text-field>

              <div style="display: grid; justify-content: center; margin-top: 20px;">
                <v-btn
                  width="336px"
                  height="40px"
                  :color="color ? color : 'rgba(34, 197, 94, 1)'"
                  @click="sendLogin"
                  style="margin-bottom: 5px; color: #fff"
                  class="mb-5"
                >
                  Login</v-btn
                >
                <v-btn
                  width="336px"
                  height="40px"
                  outlined
                  :color="color ? color : 'rgba(34, 197, 94, 1)'"
                  class="mb-5"
                  @click="changePasswordPage()"
                >
                  Esqueci minha senha
                </v-btn>

                <div class="line-container">
                    <div class="line-lefth"></div>
                    <p >ou</p>
                    <div class="line-rigth"></div>
                </div>

                <BtnLoginAD :tenantyUser="tenantyUser" />

              </div>

              </v-col>
            </v-form>
            </div>
          </v-col>

          <v-col
            class="pa-0 img-style banner"
            :style="getStyle"
            md="6"
            sm="12"
          >
            <v-img
              v-if="banner"
              contain
              height="104vh"
              max-width="100%"
              :src="banner"
              class="d-flex justify-center align-center"
            ><!--
            
              <div class="logo-container" :style="getStyle">
                <img
                  :src="logo"
                  id="logo"
                  style="text-align: center;"
                />
              </div>--->
            </v-img>

            <div
              v-else
              style="
                justify-content: center;
                align-items: center;
                display: flex;
                margin-top: 25%
              "
            >
            
              <v-img
                v-if="imgLogo"
                :src="logo"
                id="logo"
                height="300"
                width="100"
                cover
              />            
              <v-img
                style="
                position: absolute;
                top: 40%;
                left: 60%;"
                v-else
                src="../../../src/assets/logo.png"
                max-height="150"
                max-width="456"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script src="/node_modules/vue-cookie/build/vue-cookie.js'"></script>
<script>
import BtnLoginAD from "../../components/LoginAD/BtnLoginAD.vue";
//import {} from '../../../src/assets/logo.png'
export default {
  components: {
    BtnLoginAD,
  },
  data() {
    return {
      showPassword: false,
      tenantyUser: null,
      dbName: "localforage",
      storeName: "keyvaluepairs",
      code: "",
      model: {
        tenanty: "",
        email: "",
        password: "",
      },

      banner: null,
      logo: null,
      nomeEmpresa: null,
      color: null,
      imgLogo: null,
    };
  },

  computed: {
    getStyle() {
      if (this.banner == null || this.color == null) {
        return "background: linear-gradient(217deg, rgba(234, 243, 222, 1), rgba(242, 241, 212, 1) 70.71%);";
      } 
      else if(this.banner === null && this.color !== ''){
        return `background-color: ${this.color};`;
      }
      else {
        //return `opacity: 0.8; background-color: ${this.color};`;
      }
    },
  },
  methods: {
    async sendLogin() {
      if (
        this.model.tenanty != "" &&
        this.model.email != "" &&
        this.model.password != ""
      ) {
        let res = await this.$auth.login(
          this.model.tenanty,
          this.model.email,
          this.model.password
        );

        if (res.data.success) {
          // cache
          let user_login = [];
          user_login = [this.model.tenanty.toLocaleLowerCase(), this.model.email];
          window.localStorage.setItem("user-login", JSON.stringify(user_login));

          const data = await this.$auth.getUserParams();

          const flag = data.a2f;
          const firstLogin = data.firstLogin;

          const token = this.$cookies.get("hashToken");
          await this.getProfile();
          /*
          const profile = await this.$profile.getProfile();

          if(profile.data.data != undefined){
            this.nomeEmpresa = profile.data.data.nomeEmpresa;
            this.color = profile.data.data.color;
            let imgLogo = profile.data.data.logo
            this.logo = `${process.env.VUE_APP_IMG_URL}/${imgLogo}`;

            window.localStorage.setItem("color", this.color)
            window.localStorage.setItem("nomeEmpresa", this.nomeEmpresa)
            window.localStorage.setItem("logo", this.logo)
          }*/

          let s = this.$route.query.s;
          if(s == 's'){
            //window.history.back();
            let volume = this.$route.query.v;
            let tenanty = this.$route.query.t;

            let tenantyLogin = this.model.tenanty.toLocaleLowerCase();
            if(tenantyLogin == tenanty){
               this.$router.push(`/s?v=${volume}&t=${tenanty}`);
            } else {
              this.$router.push("home");
            }
          }

          else if (firstLogin == true) {
            this.$router.push("/firstAccess");
          } else {
            if (flag == 0) {
              this.$router.push("home"); 
            } else if (flag == 1 || token != null) {
              let data = await this.$auth.TwoFactorAuth2fa(token);

              var auth = data.data.data.authenticated;

              if (auth == false) {
                this.$router.push("confirmAuth");
              } else {
                this.$router.push("home");
              }
            } else {
              this.$router.push("home");
            }
          }
        }
      } else {
        this.$notfy.error("Erro, algum campo deve está em branco");
      }
    },

    async getProfile() {
      const profile = await this.$profile.getProfile();
      if (profile.data.data != undefined) {


        this.nomeEmpresa = profile.data.data.nomeEmpresa;
        this.color = profile.data.data.color;
        this.banner = profile.data.data.banner;
        this.imgLogo = profile.data.data.logo;
        this.logo = `${process.env.VUE_APP_IMG_URL}/${this.imgLogo}`;
        let dominio = profile.data.data.dominio;

        window.localStorage.setItem("color", this.color);
        window.localStorage.setItem("nomeEmpresa", this.nomeEmpresa);
        window.localStorage.setItem("dominio", dominio);
        window.localStorage.setItem("logo", this.logo);
        window.localStorage.setItem("banner", this.banner);
      }
    },

    async getTenanty() {
      if (this.model.tenanty.length >= 3) {
        var req = await this.$profile.getByTenanty(
          this.model.tenanty.toLowerCase()
        );
        this.tenantyUser = req.data.data;
        if (req.data.data != undefined) {

          this.showForm = true;
          this.imgLogo = req.data.data.logo;
          this.logo = `${process.env.VUE_APP_IMG_URL}/${this.imgLogo}`;
          this.nomeEmpresa = req.data.data.nomeEmpresa;
          this.color = req.data.data.color;
          this.banner = req.data.data.banner;
        }
      }
    },

    async changePasswordPage(){

      if(this.model.tenanty == '' || this.model.email == ''){
        this.$notfy.error("Erro, preencha os campos de Tenanty e E-mail");
        return
      }

      let data = await this.$auth.sendTokenForEmail(this.model);
      
      console.log('DATA >>> ', data)
      let token = data.data.data.token;

      if(!token){
        this.$notfy.error(`${data.data.data}`);
        return
      }

      this.$router.push({ name: "ChangePassword", params: {
        token: token,
        tenanty: this.model.tenanty,
        logo: this.logo,
        email: this.model.email,
        password: this.model.password
      } })
    }
  },
  async mounted() {
    const lc = window.localStorage.getItem("user-login");

    if (lc != null) {
      const user = JSON.parse(lc);
      this.model.tenanty = user[0];
      this.model.email = user[1];
    }

    if (this.model.tenanty != "") {
      this.getTenanty();
    }
  },
};
</script>

<style>
.login-container {
  max-width: 336px;
}

.login-container h2 {
  text-align: left;
  margin-bottom: 15px;
}

.login-container p {
  text-align: left;
  color: rgba(116, 116, 116, 1);
  font-weight: 400;
  font-size: 16px;
  font-family: sans-serif;
}

.area-access {
  background-color: rgba(0, 0, 0, 0.08);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 104vh;
  padding-top: 45px;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%); */
  box-shadow: none;
}

.banner {
  height: 104vh;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 104vh;

    margin: 0;
    padding: 0;
}

.v-image__image--cover {
  background-size: 100% 100% !important;
}

.v-image__image--contain {
  background-size: 100% 100% !important;
}

.line-lefth {
    height: 1px;
    width: 136px;
    background-color: rgba(171, 171, 171, 1);
    margin-right: 20px;
    margin-bottom: 11px;
}

.line-rigth {
    height: 1px;
    width: 136px;
    background-color: rgba(171, 171, 171, 1);
    margin-left: 20px;
    margin-bottom: 11px;
}

.line-container {
    width: 336px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 959px) {
  .img-style {
    display: none;
  }

  .area-access {
    padding-bottom: 110px;
  }
}
</style>