<template>
    <div>
        <v-alert
        :color="color"
        elevation="13"
        :type="type">{{ msg }}</v-alert>
    </div>
</template>

<script>
export default {
    name: 'Message',
    props: ["msg", "type", "color"]
}
</script>

<style>
/*.v-alert {
    text-align: center;
    max-width: 50%;
    left: 295px;
}*/
</style>