export default {
    data() {
        return {
            list: [],
            users: [],
            userLogado: '',
            pages: 0,
            emails: []
        }
    },

    created(){
    },

    methods: {
        async getUsers(value, groupId, page) {
            this.list = []
            const userId = await this.$auth.getUserParams();
            this.userLogado = userId.userId;

            if(!page) page = 1;

            const users = await this.$acessGroup.getUsersByTenanty(page, 1000, value, groupId)

            this.users = users.data.data
            this.pages = users.data.pages
            let data = users.data.data.sort(function (a, b) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            for (var i = 0; i < data.length; i++) {
                this.list.push({ name: data[i].name, id: data[i].id, email: data[i].userName });
                this.emails.push(data[i].userName)
            }

            return this.list;
        },

        async searchInput(value){
            return await this.getUsers(value)
        }
    }
}