<template>
  
  <v-form>
    <v-container>
      <v-row v-for="(campo, i) in data[0][1].enum" :key="i" style="border-bottom: 1px solid #c5c0c0;">
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            label="Status"
            v-model="array[i]"
            outlined
            readonly
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
        <v-select 
          v-model="obj[i].type"
          :items="['Action', 'User']"
          outlined
          label="Type"
          @input="typeAprov"
          @change="addTypesAprov(i)"
          :value="getStatusConfig(i, 'type', array[i])"
        >
        </v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
          v-if="obj[i].type == 'User'"
        >

          <v-autocomplete
            v-model="obj[i].aprovEmail"
            :items="emails"
            outlined
            dense
            chips
            small-chips
            label="Usuários"
            multiple
            :value="getStatusConfig(i, 'aprovEmail', array[i])"
            :loading="loading"
            @update:search-input="searchUser"
            @input="usersAprov"
            @change="addUsersAprov(i)"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="4"
          v-else-if="obj[i].type == 'Action'"
        >
          <v-autocomplete
            :items="actions"
            item-text="name"
            item-value="name"
            outlined
            dense
            chips
            small-chips
            label="Action"
            :value="getStatusConfig(i, 'action', array[i])"
            @input="actionAprov"
            @change="addactionAprov(i)"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >

          <v-text-field
            label="Mimino de aprovação"
            type="number"
            :min="0"
            outlined
            id="campo"
            :value="getStatusConfig(i, 'aprovMin', array[i])"
            :key="getMaxAprov[getMaxAprov.length - 1] == i ? key : null"
            @input="minAprovs"
            @blur="addMinAprov(i)"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
        <v-combobox
          label="Proximo status"
          :items="getProxStatus(i)"
          outlined
          clearable
          :value="getStatusConfig(i, 'proxStatus', array[i])"
          @input="inputProxStatus"
          @blur="addProxStatus(i)"
          ref="content"
        ></v-combobox>
      </v-col>

      <v-col cols="12" md="4">
        <v-combobox
          label="Status de Reprova"
          :items="getStatusReprova(i)"
          outlined
          clearable
          :value="getStatusConfig(i, 'statusReprova', array[i])"
          @input="inputStatusReprova"
          @blur="addStatusReprova(i)"
          ref="content"
        ></v-combobox>

      </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import getUsersMixin from '@/mixins/getUsersMixin'
import { getSchemaActions } from '../../services/actionService'
export default {
    mixins: [getUsersMixin],
    name: 'formStatusConfig',
    props: ['data', 'statusConfigurados', 'schemaName'],
    data() {
        return {
          usersList: [],
            type: null,
            array: [],
            minAprov: 0,
            usersEmails: null,
            proxStatus: [],
            statusReprova: null,

            items: [],
            obj: [],

            maxAprov: [],
            key: 0,

            groupsSelect: [],
            enumStatus: [],
            actions: [],
            componentKey: 0,

            actionExecute: null,
            loading: false
        }
    },

    async created(){
        this.data[0][1].enum.map(resp => {
            this.array.push(resp)
            this.obj.push({ status: resp })
        })

        this.usersList = await this.getUsers('');
        this.getActions()

    },
    computed: {
      getMaxAprov(){
        return this.maxAprov
      },
    },
    methods: {

      getStatusReprova(i){
        this.enumStatus[i] = this.data[0][1].enum
        return this.enumStatus[i].filter(resp => resp !== this.proxStatus) //this.data[0][1].enum.filter(resp => resp !== this.proxStatus)
      },

      getProxStatus(i){
        this.enumStatus[i] = this.data[0][1].enum
        return this.enumStatus[i].filter(resp => resp !== this.array[i] && resp !== this.statusReprova)
      },

      async getActions(){
        let data = await getSchemaActions(this.schemaName)
        this.actions = data.filter(item => item.callType == 'EventSchemaProperty')
      },

      async getGroups(name){
        const req = await this.$acessGroup.findAllGroup(name, 1, 50)
        this.items = [ ...req.data.data.data, ...this.items ]
      },

      async searchUser(name){
        this.loading = true
        this.usersList = await this.getUsers(name);
        
        if(this.usersEmails != null){

          this.emails = [ ...this.emails, ...this.usersEmails]
        }
        this.loading = false
      },

      getStatusConfig(posicao, campo, status){
        if(this.statusConfigurados?.id !== undefined){
          //if(this.usersEmails?.length > 0) return this.obj[posicao].aprovEmail

          if(campo == 'aprovEmail' && this.statusConfigurados?.properties.filter(resp => resp.status == status).length > 0){
            if(this.usersEmails == null){
              this.obj[posicao].aprovEmail = this.statusConfigurados?.properties.filter(resp => resp.status == status)[0].aprovEmail
              this.maxAprov[posicao] = this.obj[posicao].aprovEmail.length
              return this.obj[posicao].aprovEmail
            } else {
              return this.obj[posicao].aprovEmail
            }
          } 
          if(campo == 'action' && this.statusConfigurados?.properties.filter(resp => resp.status == status).length > 0){
            this.obj[posicao].action = this.statusConfigurados?.properties.filter(resp => resp.status == status)[0].action
            return this.obj[posicao].action
          }

          if(campo == 'type' && this.statusConfigurados?.properties.filter(resp => resp?.status == status)?.length > 0){
            if(this.type == null){
              this.obj[posicao].type = this.statusConfigurados?.properties.filter(resp => resp.status == status)[0].type
              return this.obj[posicao].type
            } else {
              return this.obj[posicao].action
            }
          }
          
          if(campo == 'aprovMin' && this.statusConfigurados?.properties.filter(resp => resp.status == status).length > 0){
            this.obj[posicao].aprovMin = this.statusConfigurados?.properties.filter(resp => resp.status == status)[0].aprovMin
            return this.obj[posicao].aprovMin
          }

          if(campo == 'proxStatus' && this.statusConfigurados?.properties.filter(resp => resp.status == status).length > 0){
            this.obj[posicao].proximoStatus = this.statusConfigurados?.properties.filter(resp => resp.status == status)[0].proximoStatus
            this.getProxStatus(posicao)
            return this.obj[posicao].proximoStatus
          }

          if(campo == 'statusReprova' && this.statusConfigurados?.properties.filter(resp => resp.status == status).length > 0){
            this.obj[posicao].reprovaStatus = this.statusConfigurados?.properties.filter(resp => resp.status == status)[0].reprovaStatus
            //this.getProxStatus(posicao)
            return this.obj[posicao].reprovaStatus
          }
          this.$emit('form', this.obj)
        }
      },
        async getUsersStatusFlow(value){
            let users = await this.getUsers(value, '', 1);
            return this.items = users
        },

        typeAprov(value){
          this.type = value
          //console.log('Type ', this.type)
        },

        actionAprov(value){
          this.actionExecute = value
        },

        async usersAprov(data){
            this.usersEmails = data
        },

        minAprovs(value){
            this.minAprov = value
        },

        inputProxStatus(value){
          this.proxStatus = value
        },

        inputStatusReprova(value){
          this.statusReprova = value
        },

        addMinAprov(i){
          this.obj[i].aprovMin = Number(this.minAprov)
          this.$emit('form', this.obj)
          this.minAprov = ''
        },

        addTypesAprov(i){
          this.obj[i].type = this.type
          this.$emit('form', this.obj)
          this.$forceUpdate()
        },

        addactionAprov(i){
          this.obj[i].action = this.actionExecute
          this.$emit('form', this.obj)
          this.actionExecute = ''
        },

        addUsersAprov(i){
          this.obj[i].aprovEmail = this.usersEmails
          this.maxAprov[i] = this.obj[i].aprovEmail?.length
          this.$emit('form', this.obj)
          this.usersEmails = ''
        },

        addStatusReprova(i){
          this.obj[i].reprovaStatus = this.statusReprova
          this.$emit('form', this.obj)
          //this.statusReprova = ''
        },

        addProxStatus(i){
          this.obj[i].proximoStatus = this.proxStatus
          this.$emit('form', this.obj)
          //this.proxStatus = ''
        }
    },

  watch: {
    usersEmails(){
      this.key += 1
      //this.$forceUpdate()
    },

    list(){
      //console.log("USERS WATCH >>> ", value)
      //this.list = [ ...this.list, ...this.usersEmails ]
    },

    proxStatus(){
      this.getStatusReprova()
    },

    statusReprova(){
      this.getProxStatus()
    }
  }
}
</script>

<style>
</style>