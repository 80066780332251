<template>
	<div>
		<v-runtime-template :template="result.view" style="overflow-x: scroll !important;">
		</v-runtime-template>
		<template v-if="result.model === 'chart'">
			<ActionChartJs  @close="close" :result="result"></ActionChartJs>
		</template>
		<template v-else-if="result.model === 'pivoTable'">
			<ActionPivoTable :result="result" />
		</template>
	</div>
</template>

<script>
	import EntityService from "../../services/entityService"
	import ActionChartJs from './actionChartJs.vue'
	import ActionPivoTable from './actionPivoTable.vue'
	import xlsx from "json-as-xlsx";
	import VRuntimeTemplate from "v-runtime-template";
	import * as VuetifyComponents from "vuetify/lib/components";

	export default {
		name: "ActionResultCustom",

		props: {
			result: Object,
		},

		components: {
			ActionPivoTable,
			ActionChartJs,
			VRuntimeTemplate,
			...VuetifyComponents,
		},

		data() {
			return {
				...this.result?.data,
				headers: [],
				itemsData: [],
				count: 0
			};
		},

		computed: {
			items() {
				if (!this.result?.data) return [];
				else return this.result?.data;
			},
		},

		methods: {
			ExportAsExcelFile() {
				const columns = this.result.data.headers.map((header) => {
					return {
						label: header.text,
						value: header.value,
					};
				});
				const content = this.items.results;
				const data = [
					{
						sheet: "Planilha",
						columns,
						content,
					},
				];

				const options = {
					fileName: "SimjobExport",
				};

				try {
					xlsx(data, options);
				} catch (err) {
					this.result.view = `
					<v-alert type="error">
            ${err}
          </v-alert>`;
				}
			},

			close() {
				this.$emit("close");
			},
			runAction(identifier, params) {
				this.$emit("run-action", identifier, params);
			},
/*
			async searchEntity(search, schemaName){

				if(search !== null){
					console.log('search ', search, schemaName)
					
					console.log('typeOperators ', this.typeOperators)
					/*
					let entityService = new EntityService(schemaName)
					let res = await entityService.searchAutoComplete('description', search);
					console.log(res)
					this.typeOperators = res.data.data.data
					this.search = search
					console.log('search ', this.typeOperators)
					this.count += 1
				}

			},*/

			async createEntity(schemaName, obj){
				if(schemaName && obj){
					let entityService = new EntityService(schemaName)
					let data = await entityService.autoInc(schemaName, "Code", "######")
					obj.code = data.data
					console.log('obj ', obj)
					await entityService.insertOrUpdate(obj, null)

					this.$emit("close");
				}
			},

			async deleteEntity(schemaName, id){
				let entityService = new EntityService(schemaName)
				if(id){
					await entityService.delete(id)
					this.$emit("close");
				} 
			},

			async editEntity(schemaName, obj){
				if(schemaName && obj.id){
					let entityService = new EntityService(schemaName)
					await entityService.insertOrUpdate(obj, null)

					this.$emit("close");
				}
			},

			async getAllEntity(schemaName, obj){
				this.headers = []
				this.itemsData = []

				let entityService = new EntityService(schemaName)
				let data = await entityService.getAll(1, 30, '', '', [], null)

				Object.entries(obj).map(item => {
					this.headers.push({ text: item[0], value: item[0] })
				})

				this.itemsData = data.data.data.data
				console.log('items ', this.itemsData)

				this.result.view =
				`<div>
					<v-data-table
					    :headers="headers"
						:items="itemsData"
						class="elevation-1"
						disable-sort
						hide-default-footer
						:single-expand="false"
					>
					</v-data-table>
				</div>`
			}
		},

		mounted() {},
	};
</script>

<style></style>
