<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <FormTable :schemaName="'PRINT_RESOURCES'" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
//import EntityService from '../../services/entityService';
import FormTable from '@/components/table/FormTable';
export default {
    name: 'configPrintForm',
    components: {
        FormTable
    },
    data(){
        return {
           // entityService: new EntityService('PRINT_RESOURCES')
        }
    },

    mounted(){
    }
}
</script>

<style scoped>

</style>