<template>
  <div>
    <v-dialog cen v-model="dialog" width="500" content-class="dialog-sobre">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-icon>
            <v-icon>mdi-checkbox-blank-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sobre</v-list-item-title>
        </v-list-item>
      </template>

      <v-card content-class="card">
        <v-card-title class="text-h5 grey lighten-2"> Sobre </v-card-title>

        <v-spacer></v-spacer>

        <v-img
          class="img"
          :src="require('/src/assets/logo-simjob.png')"
          max-width="180"
          max-height="200"
        />

        <v-card-text>
          <strong>Versão do front-end: {{ version }}</strong>
        </v-card-text>

        <v-card-text>
          <strong>Versão da API: {{ versionApi }} </strong>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import packageConfig from "../../../package.json";
export default {
  data() {
    return {
      version: packageConfig.version,
      dialog: false,
      versionApi: "",
    };
  },

  async mounted() {
    console.log('TESTE VERSION ', packageConfig.version)
    const params = await this.$auth.getUserParams();
    this.versionApi = params.version ? params.version : "carregando...";
  },
};
</script>

<style scoped>
.img {
  margin: auto;
}

.card {
  margin-left: 500px;
}

.v-btn {
  margin-top: 15px;
}

.dialog-sobre {
  width: 500px !important;
}

.v-dialog__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
  width: 100%;
  z-index: 6;
  outline: none;
}
</style>
