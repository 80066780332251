import Vue from "vue";

async function getSeparateActions () {
  const response = await Vue.prototype.$http.get('/Action/search-fields', { 
    params: {
      value: "Separate",
      searchFields: "callType"
    }
  })
  if (!response?.data?.success) {
    throw new Error("Unable to get separate actions from API")
  }
  if (!response?.data?.data?.data?.length) return []
  return response?.data?.data?.data//?.filter(action => action.callType === "Separate")
}

async function getSchemaActions (schemaName) {
  const response = await Vue.prototype.$http.get('/Action/search-fields', { 
    params: {
      value: schemaName,
      searchFields: "callConfig.schemaName"
    }
  })
  if (!response?.data?.success) {
    throw new Error("Unable to get separate actions from API")
  }
  if (!response?.data?.data?.data?.length) return []

  return response?.data?.data?.data?.filter(action => (action.callType === "Schema" || action.callType === "TabForm" || action.callType === "EventSchemaProperty") && action?.callConfig?.schemaName === schemaName)
}

async function getActionById (id) {
  const response = await Vue.prototype.$http.get(`/Action/${id}`)
  if (!response?.data?.success || !response?.data?.data) {
    throw new Error("Unable to get action from API")
  }

  return response?.data?.data
}

async function getActionByName (name) {
  const response = await Vue.prototype.$http.get('/Action/search-fields', { 
    params: {
      value: name,
      searchFields: "name"
    }
  })
  if (!response?.data?.success) {
    throw new Error("Unable to get action by name from API")
  }
  if (!response?.data?.data?.data?.length) return []

  return response?.data?.data?.data?.find(action => action.name === name)
}

async function runAction (identifier, params = {}, entityId = '') {

  params.base_url = process.env.VUE_APP_API_URL;
  params.token = await Vue.prototype.$auth.getUserToken();

  const body = {
    params
  }
  if (identifier.id) {
    body.actionId = identifier.id
  }
  else if (identifier.name) {
    body.actionName = identifier.name
  }
  else {
    throw new Error("No action to run")
  }
  if (entityId) body.entityId = entityId
  const response = await Vue.prototype.$http.post('/Action/run', body)

  //console.log('Response ', response)

  if (!response?.data?.success) {
    throw new Error("Unable to run action")
  }

  const log = await Vue.prototype.$http.get(`${process.env.VUE_APP_ACTION_URL}/actions/log`)
  console.log(log.data)
  //console.log("response data ", response.data.data)
  return response?.data?.data
}

export { getSeparateActions, getSchemaActions, getActionById, getActionByName, runAction }