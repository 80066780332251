<template>
  <v-menu
    v-bind="menuProps"
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-bind="textFieldProps"
        v-on="on"
        @click="temp = lazyValue"
        @input="formattedDate = $event"
        :value="
          focusedText
            ? temp
              ? formatDate(displayFormat, temp)
              : ''
            : formattedDate
        "
        @focus="focusedText = true"
        @blur="focusedText = false"
      ></v-text-field>
    </template>
    <v-date-picker v-bind="datePickerProps" v-model="temp">
      <v-btn text color="primary" @click="reset">
        Redefinir
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="cancel">
        Cancelar
      </v-btn>
      <v-btn text color="primary" @click="save">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "DatePickerField",

  props: {
    value: {
      type: String,
      required: false
    },

    textFieldProps: {
      type: Object,
      required: false,
      default() {
        return {
          "prepend-icon": "mdi-calendar"
        };
      }
    },

    menuProps: {
      type: Object,
      required: false,
      default() {
        return {
          "offset-y": true
        };
      }
    },

    datePickerProps: {
      type: Object,
      required: false,
      default() {
        return {
          "no-title": true,
          scrollable: true
        };
      }
    },

    displayFormat: {
      type: String,
      required: false,
      default: "default"
    },

    valueFormat: {
      type: String,
      required: false,
      default: "default"
    }
  },

  data() {
    return {
      menu: false,
      focusedText: false,

      lazyValue: this.parseDate(this.valueFormat, this.value),
      temp: null
    };
  },

  watch: {
    value: {
      handler(val) {
        this.lazyValue = this.parseDate(this.valueFormat, val);
      }
    }
  },

  computed: {
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        this.lazyValue = val;
        this.$emit("input", this.formatDate(this.valueFormat, val));
      }
    },

    formattedDate: {
      get() {
        if (!this.internalValue) return null;
        else return this.formatDate(this.displayFormat, this.internalValue);
      },

      set(val) {
        const parsed = this.parseDate(this.displayFormat, val);
        if (parsed === "Invalid DateTime") console.log("a");
        this.internalValue = parsed;
      }
    }
  },

  methods: {
    save() {
      this.internalValue = this.temp;
      this.menu = false;
      this.temp = null;
    },

    cancel() {
      this.menu = false;
      this.temp = null;
    },

    reset() {
      this.$emit("reset");
      this.menu = false;
      this.temp = null;
      this.lazyValue = this.value;
    },

    parseDate(format, formattedDate) {
      let date;
      if (format === "default") {
        date = DateTime.fromISO(formattedDate);
      } else {
        date = DateTime.fromFormat(formattedDate, format);
      }
      if (date.isValid) return date.toISODate();
      else return null;
    },

    formatDate(format, date) {
      if (format === "default") {
        return DateTime.fromISO(date).toISODate();
      } else return DateTime.fromISO(date).toFormat(format);
    }
  }
};
</script>

<style></style>
