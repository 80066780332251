<template>
    <v-text-field
    v-model="search"
    label="Pesquisa"
    placeholder="Pesquisar schema"
    outlined
    dense
    @input="$emit('search', search)"
    ></v-text-field>
</template>

<script>
export default {
    props: ["userId"],
    name: 'PesquisaSchema',
    data(){
        return {
            search: ''
        }
    },

    watch: {
        userId(){
            this.search = ''
        }
    }
}
</script>