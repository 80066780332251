import Vue from "vue";

export default {

    async getAllGroupAccess(page){
        let req = await Vue.prototype.$http.get('/UserAccess/getAllUserAccess', { params: {
            page: page, limit: 30
        }});
        return req;
        //console.log('userAccess')
    },

    async getRecordId(id){
        let req = await Vue.prototype.$http.get(`/UserAccess/getBySchemaRecordId?schemaRecordId=${id}`);
        return req;
    },

}