<template>
  <div>
    <Message :msg="msg" :type="type" :color="color"/>
    <v-app id="inspire">
      <v-main>
        <v-container fluid fill-height>
          <v-layout align-center justify-center >
            <v-flex xs5 sm8 md4>
              <v-card class="elevation-8">
                <v-toolbar dark :color="colorCard == null ? 'white' : colorCard">
                  <v-toolbar-title>Insira o código</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form ref="form">
                    <v-col cols="12" align="center" >
                      <v-img  
                        :src="logo"
                         max-width="180"
                         max-height="200"
                      />
                    </v-col>
                    <v-text-field
                      :rules="codeRules"
                      name="confirm"
                      counter="6"
                      label="Codigo"
                      type="text"
                      v-model="codigo"
                      required
                    ></v-text-field>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit" @click="confirmAuth($event)">{{ text }}</v-btn>
                </v-card-actions>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Message from '../message/Message.vue';
export default {
    name: "Card",

    components: {
      Message
    },

    data(){
        return{
          codeRules: [ v => !!v || 'Code is required', v => v.length <= 6 || 
          'Codigo com 6 digitos' ],
            text: "Confirmar código",
            codigo: "",
            token: "",
            msg: "Foi enviando um código para o seu e-mail",
            type: "success",
            color: "blue",

            logo: null,
            colorCard: null,
            auth: ''
        }
    },

    beforeDestroy(){
      if(!this.auth){
       this.$router.back()
      }
    },

    methods: {

        resetValidation() {
          this.$refs.form.resetValidation();
        },
        async confirmAuth(e){
          e.preventDefault();

          this.token = this.$cookies.get("hashToken")

          if(this.text == "Reenviar código"){
            await this.$auth.TwoFactorAuth2fa(this.token);
            this.text = "Confirmar Código"
            this.msg = "O código foi reenviado"
            this.type = "success"
            this.color = "blue"
            
            this.codigo = ""
            this.resetValidation()
          }

          else if(this.codigo != ""){
            let data = await this.$auth.confirmAuthService(this.codigo, this.token);
            
            this.auth = data.data.data.authenticated

            if(this.auth == true){
              this.$cookies.set("hashToken", await this.$auth.getHash()) ;
              const data = await this.$auth.getUserParams();

              await this.$auth.enableDisable2fa(data.userId, 0);
              data.a2f = 0;
              await this.$auth.saveUserParams(data);

              this.$notfy.success("Código valido")
              this.$router.push("Home")
            } else {
              this.$notfy.error("Erro, código invalido ou expirado");
            }
          }
        },

        messageButtonAndMessage(){
            setTimeout(() => {
              this.text = "Reenviar código"
              this.msg = "Código expirado"
              this.type = "error"
              this.color = "red"
            }, 120000) // 120000
        },

        async getTenanty(){
          const lc = window.localStorage.getItem("user-login");
          const user = JSON.parse(lc);
          let tenanty = user[0];

          var req = await this.$profile.getByTenanty(tenanty);
          if(req.data.data != undefined){
            this.logo = `${process.env.VUE_APP_IMG_URL}/${req.data.data.logo}`;
            this.colorCard = req.data.data.color;
          }
        },
    },
    
    mounted() {
      this.getTenanty();
      this.messageButtonAndMessage();
    }
}
</script>

<style scoped>
.v-card {
  margin-bottom: 100px;
}
</style>