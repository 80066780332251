<template>
    <v-dialog
      v-model="dialog"
      width="600px"
      @click:outside="closoDialog"
    >

      <v-card>
        <v-card-title style="justify-content: space-between;">
            <div>
                <span class="text-h5">Token de acesso</span>
            </div>
            <div>
                <span @click="closoDialog" style="cursor: pointer">X</span>
            </div>
        </v-card-title>
        <v-card-text>
            {{externalTokenMessage}}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="externalToken"
          >
            <i class="bi bi-arrow-clockwise" style="font-size: 30px"></i>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script>
export default {
    props: ["value", 'userId'],
    data(){
        return {
            dialog: false,
            externalTokenMessage: null
        }
    },

    watch: {
        value(v){
            this.dialog = v

            if(this.dialog){
                this.getExternalToken()
            }
        }
    },

    methods: {

        closoDialog(){
            this.$emit('close')
            this.externalTokenMessage = null
        },

        async externalToken(){

            const obj = {
                userId: this.userId
            }

            const req = await this.$auth.externalToken(obj)

            if(req.status == 200){
                this.$notfy.success("Operação realizada com sucesso")
                this.externalTokenMessage = req.data.userToken

            }else {
                this.$notfy.error("Falha")
            }
        },

        async getExternalToken(){
            try {
                const req = await this.$auth.getExternalToken(this.userId)
                if(!req){
                    this.$notfy.error("Erro, talvez o token do usuário esteja expirado")
                }
                this.externalTokenMessage = req.data.data.userToken
            } catch(err){
                //
            }

        }
    },
}
</script>