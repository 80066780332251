<template>
  <div>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="rowClick(item)"
      >
        <v-list-item-icon>
          <v-icon :color="item.type == 'Input' ? 'success' : 'warning'"
            >mdi-menu-right</v-icon
          >
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            >{{ item.description }} - Qt: {{ item.valueEst }}</v-list-item-title
          >
        </v-list-item-content>
        <v-card-actions>
 
          <v-btn icon color="warning" @click="editItem(item)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn icon color="error" @click="deleteItem(item)"
            ><v-icon>mdi-minus</v-icon></v-btn
          >
        </v-card-actions>
      </v-list-item>
    </v-list>

    <entity-form-dialog
      v-if="schema"
      v-model="formDialog"
      :schema="schema"
      :form="schema.form"
      :entity="itemSelected"
      :loading="loading"
      @confirm="saveItem"
      @delete="deleteItem"
      @cancel="cancelFormDialog"
      @dialog="($event) => (formDialog = $event)"
    ></entity-form-dialog>
  </div>
</template>

<script>
import entityPageMixin from "../../mixins/entityPageMixin";
export default {
  props: ["schemaName", "ids", "relation", "reference"],
  components: {
    entityFormDialog: () =>
      import("../../components/dialogs/entityFormDialog.vue"),
  },
  mixins: [entityPageMixin],
  data() {
    return {
      pagination: {
        page: null,
        limit: null,
        pages: null,
      },
    };
  },
  methods: {
     async deleteIdInProps(item) {
      if (this.relation != null) {
        let idsUpdate = Array.from(this.ids);
        let itemDeleted = idsUpdate.find(id => id == item.id);
        let index = idsUpdate.indexOf(itemDeleted);
        idsUpdate.splice(index,1)
        await this.$emit("input-ids", {
          ids: idsUpdate,
          reference: this.reference,
        });
      }
    },
    async saveIdsInProps(res) {
      if (this.relation != null && !this.itemSelected.id) {
        let idsUpdate = [];
        if (this.ids && this.ids.length > 0) idsUpdate = Array.from(this.ids);

        idsUpdate.push(res.data.data.id);

        await this.$emit("input-ids", {
          ids: idsUpdate,
          reference: this.reference,
        });
      }
    },
  },
  //   watch: {
  //     async schemaName() {
  //       //this.entityService = new EntityService(this.schemaName);
  //       //await this.getSchema();
  //     },
  //   },
  //   async mounted() {
  //     this.itemSelected = Object.assign({}, this.value);
  //     await this.getSchema();
  //   },
};
</script>

<style>
</style>