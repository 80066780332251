<template>
    <div>
        <div v-show="showCam" style="text-align: center;">
            <video autoplay="true" id="webcam" style="width: 90%"></video>
            <v-btn color="blue" @click="foto()" style="margin: 10px; border-radius: 150px; height: 40px;
                width: 50px;">
                <i style="font-size: 26px;" class="bi bi-camera-fill"></i>
            </v-btn>
        </div>
        
        <v-file-input
        :accept="accept"
        v-model="fliesModel"
        color="deep-primary accent-4"
        counter
        :label="label"
        multiple
        :placeholder="label"
        :disabled="readonly"
        prepend-icon="mdi-paperclip"
        outlined
        @change="change(fliesModel, $event)"
        >
        <template v-slot:selection="{ index, text }">
        <v-chip
        close
        @click:close="deleteChip(index)"
            color="deep-primary accent-4"
            dark
            label
            small
        >
            <small>{{ text }}</small>
        </v-chip>

        </template>

        <template v-slot:append-outer>
            <i style="font-size: 26px; cursor: pointer" class="bi bi-camera"  @click="camera"></i>
            <!--
            <v-icon
                @click="camera"
                color="blue"
                >mdi-plus</v-icon>--->
            </template>
        </v-file-input>

        <p v-if="filesCad === undefined || files != []" style="font-weight: bold;">Arquivos Atuais:</p>
        <div style="display: flex; overflow: auto;" v-if="filesCad === undefined || files != []">
            <div v-for="(file, index) in files" :key="index" style="margin: 5px; position: relative;">
                <img  v-if="file.base64 && file.base64.split('/')[0].split(':')[1] == 'image'" :src="`${file.base64}`" class="mini-images">
                <v-icon v-else class="mini-images" style="font-size: 78px;">mdi-file</v-icon>
            </div>  
        </div>

       <p v-if="filesCad != undefined" style="font-weight: bold;">Arquivos Existentes:</p>
       <div style="display: flex; overflow: auto;" v-if="filesCad != undefined">
            <div v-for="(file, index) in filesCad" :key="index" style="margin: 5px; position: relative;">
                <span class="remove-item" @click="removeItemExist(index)">x</span>
                <a style="text-decoration: none;" download :href="file" class="download-item"><v-icon style="font-size: 18px;">mdi-download</v-icon></a>
                <img  v-if="typeExtensionImage.includes(file.split('.')[file.split('.').length-1])" :src="`${file}`" class="mini-images">
                <v-icon v-else class="mini-images" style="font-size: 78px;">mdi-file</v-icon>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ["filesCad", "accept", "multiple", "label", "readonly"],
    name: 'InputFile',
    data(){
        return {
            files: [],
            fotoCam: '', 
            showCam: false,
            fliesModel: [],
            typeExtensionImage: ['png','jpg','jpeg','tiff','svg','ico']
        }
    },
    methods: {
        change(e){
            let arrayFiles = []
            this.files = []
            let count = 0
             for(let i = 0; i < e.length; i++){
                let name = e[i].name;

                let extension = name?.split('.')[name.split('.').length - 1]
                var reader = new FileReader();
                reader.readAsDataURL(e[i]);
                reader.onload = (f) => {
                    let base64 = f.target.result;
                    let type = base64.split(',')[0].split(';')[0].split('/')[1];
                    base64 = base64.replace(type,extension);
                    let obj = {
                        base64: base64.toString(),
                        fileName: name
                    }
                    this.files.push(obj)
                    arrayFiles.push(obj)
                    count++

                    if(e.length == count){
                        this.$emit('file', arrayFiles)
                    }
                }
            }
            //this.$emit('runActionInputFile', $event)
        },

        deleteChip(index) {
            // Prompt here with text if required
            this.fliesModel.splice(index, 1)
            this.files.splice(index, 1)
        },

        removeItemExist(index){
            this.filesCad.splice(index, 1)
            this.$emit('fileCad', this.filesCad)
        },

        camera(){
            this.showCam = true
            let video = document.querySelector("#webcam");
            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({audio: false, video: {facingMode: 'user'}})
                .then( function(stream) {
                    console.log('stream ', stream)
                    video.srcObject = stream;
                })
                .catch(function(error) {
                    alert("Não foi possível iniciar a webcam. ", error);
                });
            }
        },

        foto(){
            let video = document.querySelector("#webcam");
            
            let canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            let ctx = canvas.getContext('2d');
            
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            
            let dataURI = canvas.toDataURL('image/jpeg');
            this.fotoCam = dataURI
            
            let obj = {
                base64: dataURI,
                //fileName: name
            }
            this.files.push(obj)
            this.$emit('file', this.fotoCam)

            this.showCam = false
        }
    },

    mounted(){
    }
}
</script>


<style scoped>

    .mini-images {
        height: 70px;
        margin: 10px;
        width: 70px;
        object-fit: cover;
    }

    .image-camera {
        height: 100px;
        margin: 10px;
    }

    .remove-item {
        display: flex;
        position: absolute;
        right: 0;
        background: red;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        font-size: 15px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
    }

    .download-item {
        display: flex;
        position: absolute;
        right: 18px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        font-size: 15px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
    }
</style>