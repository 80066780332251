<template>
  <v-row>
    <v-dialog v-model="dialog" max-width="600px" :key="key">
      <!--
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-on="on" v-bind="attrs" class="icon-update">
          mdi-pencil
        </v-icon>
      </template>--->
      <v-card>
        <div v-if="msg != null" class="msg">
          <Message :msg="msg" :type="type" :color="color" />
        </div>
        <v-card-title>
          <span class="text-h5">Grupo</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="group"
              label="Nome"
              placeholder="Nome do grupo"
              outlined
              dense
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="updateGroup(group, id)">
            Atualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Message from "../message/Message.vue";

export default {
  props: ["groupName", "id", "open"],
  components: { Message },
  data: () => ({
    key: 0,
    dialog: false,

    msg: null,
    color: "blue",
    type: "success",
    group: null
  }),

  watch: {
    open(v){
      this.dialog = v;
      this.key += 1
      //console.log('Value ', this.dialog)
      this.group = this.groupName
    }
  },

  methods: {
    async updateGroup(groupName, id) {
      if (this.group == "" || this.group == null) {
        this.$notfy.error("Nome do grupo não foi informado");
        this.$forceUpdate();
      } else {
        let data = await this.$acessGroup.updateGroup(groupName, id);
        if (data.data.success) {
          this.$notfy.success("Grupo atualizado");
        }
        if (!data.data.success) {
          console.log(data);
          const errorMessage = data.data.data[0].value;
          this.$notfy.error(errorMessage);
        }
        this.dialog = false;
        this.$emit("close");
      }
    },
  },

  mounted() {},
};
</script>

<style scoped>
.icon-update {
  margin-bottom: -5px;
}
</style>
