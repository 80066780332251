import apiClient from "../api/apiClient";
import authService from "../services/authService"
class RepoServise {
  constructor(routeApi) {
    this.routeApi = routeApi;
    this.http = apiClient.createHttp();
  }

  routeApi;
  http;

  async insertOrUpdate(entity, position) {
    
    const userIp = await authService.getIpClient()
    const user = await authService.getUserParams()
    const schema = this.routeApi.split('Entity/')

    if(!position) position = {}
    
    return entity.id
    ? await this.http.put(this.routeApi, entity, {params: 
        { latitude: position?.latitude, longitude: position?.longitude, ip: userIp,
          userAgent: window.navigator.userAgent, 
          description: `A entidade ${schema[1]} foi atualizada pelo usuário ${user.user}`}})
      
      : await this.http.post(this.routeApi, entity, {params: 
        { latitude: position?.latitude, longitude: position?.longitude, ip: userIp, 
          userAgent: window.navigator.userAgent,
          description: `O usuário ${user.user} realizou um insert no ${schema[1]}`}});
  }

  async delete(id) {
    return await this.http.delete(this.routeApi, { params: { id } });
  }

  async getAll(page, limit, search, searchFields, ids, mode, companySiteId) {
    if (
      search &&
      search.length > 0 &&
      searchFields &&
      searchFields.length > 0
    ) {
      return await this.search(
        searchFields,
        search,
        page,
        limit,
        ids ? ids.toString() : undefined,
        mode,
        companySiteId
      );
    }
    return await this.http.get(this.routeApi, {
      params: { page, limit, companySiteId, ids: ids ? ids.toString() : undefined },
    });
  }

  async getAllSearchItems(page, limit, schemaName, relationSchema, id, search, searchFields){
    return await this.http.get(`Entity/${schemaName}` + '/search-items', {
      params: { page, limit, schemaName, relationSchema, id: id, value: search, 
        searchFields: search.length > 0 ? searchFields : null },
    });
  }

  async getAllBySearchDef(page, limit, searchDef, search, searchFields) {
    return await this.http.post('/search-fieldsDefs', 
      searchDef?.defs,
    {
      params: { 
        idSearchDefs: searchDef?.id, 
        schemaNameDef: searchDef?.schemaName,
        value: search,
        searchFields: searchFields,
        page, limit  },
      //baseURL: 'https://api.simjob.net/api/Entity'
      baseURL: process.env.VUE_APP_API_URL + '/Entity'
    });
  }

  async getAllByIds(page, limit, search, searchFields, mode) {
    if (
      search &&
      search.length > 0 &&
      searchFields &&
      searchFields.length > 0
    ) {
      return await this.search(searchFields, search, page, limit, null, mode);
    }
    return await this.http.get(this.routeApi, { params: { page, limit } });
  }

  async search(searchFields, value, page, limit, ids, mode, companySiteId) {
    searchFields = searchFields ? searchFields.toString() : null;
    return await this.http.get(this.routeApi + "/search-fields", {
      params: {
        searchFields,
        value,
        page,
        limit,
        ids: ids ? ids : undefined,
        mode,
        companySiteId
      },
    });
  }



  async getById(id) {
    return await this.http.get(this.routeApi + "/" + id);
  }
}

export default RepoServise;
