<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card>
    <div>
      <v-app-bar flat color="transparent">
        <v-btn large icon @click="$emit('cancel')">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>Motivo da Reprova</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn large icon @click="textArea()">
          <v-icon color="primary">mdi-check</v-icon>
        </v-btn>
      </v-app-bar>

      <v-textarea height="80" label="Informe o motivo da reprova" outlined v-model="motivo" />
    </div>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'dialogObsReprov',
    props: ['value'],
    data() {
        return {
            motivo: null,
            dialog: false
        }
    },


    methods: {
        textArea(){
            console.log(this.motivo)
            this.$emit('obsReprov', this.motivo)

            this.motivo = ''
            this.$emit('cancel')
        }
    },

    watch:{
        value(v){
            console.log('dialog ', v)
            this.dialog = v  
        },
    }
}
</script>