<template>
    <div>
        <v-card>
            <v-card-title class="text-center justify-left py-6">
            <h2 class="font-weight-bold">
                Perfil da empresa
            </h2>
            </v-card-title>

            <v-tabs
            v-model="tab"
            background-color="transparent"
            grow
            >
            <v-tab
                v-for="item in items"
                :key="item"
            >
                {{ item }}
            </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                
            <v-tab-item>
                <v-card flat>
                    <AccountProfileForm />
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <ConfigBucketForm />
                </v-card>
            </v-tab-item>

            <v-tab-item>
                <v-card flat>
                    <ConfigPrintForm />
                </v-card>
            </v-tab-item>


        </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import AccountProfileForm from '../../components/form/accountProfileForm.vue';
import ConfigBucketForm from '../../components/form/configBucketForm.vue';
import ConfigPrintForm from '../../components/form/configPrintForm.vue';
    export default {
        components: {
            AccountProfileForm,
            ConfigBucketForm,
            ConfigPrintForm
        },
        data(){
            return {
                tab: null,
                items: [
                'Configuração da empresa', 'Configuração de bucket', 'Configuração da Impressora'
                ],
            }
        }
    }

</script>

<style lang="scss" scoped>

</style>