import Vue from "vue";

export default {

    async insertProfile(profile) {
        const req = await Vue.prototype.$http.post(`/Profile/insert`, profile);
        return req;
    },

    async getProfile(){
        const req = await Vue.prototype.$http.get("/Profile/getByTenanty")
        return req;
    },

    async getAll(){
        const req = await Vue.prototype.$http.get('/Profile/getAll');
        return req;
    },

    async insertLogo(logo){
        const req = await Vue.prototype.$http.post('/Profile/insertLogo', logo)
        return req; 
    },

    async getByTenanty(tenanty){
        const req = await Vue.prototype.$http.get(`/Profile/getByTenanty/${tenanty}`);
        return req;
    }

/*
    async updateProfile(id, color, profile) {
        const req = await Vue.prototype.$http.put(`/Profile/update${id}?color=${color}`, profile);
        return req;
    },

    async getAll(){
        const req = await Vue.prototype.$http.get('/Profile/getAll');
        return req;
    },

    async getById(id){
        const req = await Vue.prototype.$http.get(`/Profile/getById${id}`);
        return req;
    },

    async delete(id){
        const req = await Vue.prototype.$http.delete(`/Profile/deleteById${id}`);
        return req;
    }*/
}