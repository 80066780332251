<template>
  <v-dialog
    v-model="open"
    persistent
    scrollable
    :key="count"
    @input="($event) => $emit('dialog', $event)"
    content-class="my-custom-dialog"
  >
    <v-card class="my-custom-card">
      <v-app-bar v-if="show == true" class="mb-5" flat color="transparent">
        <v-btn large icon @click="$emit('cancel')">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="title">{{
          schema.description
        }}
        <span v-if="aprove == null"> - Aguardando aprovação</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <entity-form-dialog-full-screen 
          v-if="width > 560 && (getPermissionCrud(schema, 'Create') > 0 && !entity.id) || (getPermissionCrud(schema, 'Update') > 0 && entity.id)"
          :schema="schema" 
          :entity="entity" 
          :propertyActions="propertyActions"
          :actions="schemaActions"
          :loading="loading"
          @runAction="requestRunSchemaAction"
          @save="$emit('confirm')"
          @delete="$emit('delete')"
        />

        <div v-if="(getPermissionCrud(schema, 'Create') > 0 && !entity.id) || (getPermissionCrud(schema, 'Update') > 0 && entity.id)">
          <v-btn :disabled="loading ? false : true" v-if="aprove != null"  large icon color="success" @click="$emit('confirm'), verifyCampos()" >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </div>

        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" large icon color="primary">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list v-if="aprove != null">
            <v-list-item
              v-for="(item, index) in schemaActions"
              :key="index"
              link
              @click="requestRunSchemaAction(item)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.description }}</v-list-item-title>
            </v-list-item>
            <v-divider v-if="schemaActions.length > 0"></v-divider>
            <v-list-item v-if="entity.id && getPermissionCrud(schema, 'Delete') > 0" @click="$emit('delete')">
              <v-list-item-icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Deletar</v-list-item-title>
            </v-list-item>
          </v-list>

          <v-list v-if="aprove == null && aproveUser == null" >
            <v-list-item @click="aprovarOrReprovar(true)">
              Aprovar
            </v-list-item>
            <v-list-item @click="openObsDialog = true">
              Reprovar
            </v-list-item>
          </v-list>

          <v-list v-if="options !== undefined">
            <v-list-item v-for="(option, key) in options" :key="key" @click="$emit('eventOption', option)">
              <v-list-item-title>
                {{ option }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <div>
          <Dialog v-model="active" :value="active" @cancel="close" />
        </div>

        <div>
          <DialogObsReprov v-model="openObsDialog" :value="openObsDialog" 
          @obsReprov="getObsReprov"
          @cancel="openObsDialog = false" />
        </div>
        
      </v-app-bar>
      <!--
      <v-col
      v-if="show == false"
        cols="12"
        md="4"
      > --->
      <v-skeleton-loader
      v-if="show == false"
        style="margin-top: 40px"
        type="article, article, article, article, article, actions"
      ></v-skeleton-loader>
      <!-- </v-col> --->

      <v-container> </v-container>
      <div>
        <entity-form-page
          v-show="show == true"
          v-if="schema && entity"
          :aprovClick="aprovClick"
          :schema="schema"
          :isOpen="open"
          :entity="entity"
          :tabActions="tabActions"
          :propertyActions="propertyActions"
          :col="'col-12'"
        ></entity-form-page>

        <v-expansion-panels multiple class="mt-12" v-if="show && apontamentos != undefined">
          <v-expansion-panel v-if="apontamentos[0]">
            <v-expansion-panel-header class="font-weight-bold">
              Apontamentos
            </v-expansion-panel-header>
            
            <v-expansion-panel-content>

              <v-list>
    <!--            
                <v-list-item v-for="apontamento in apontamentos" :key="apontamento.id">
                  <div class="d-block border-b">

                    <p>
                      {{  apontamento.createBy.split('@')[0] }} - {{ new Date(apontamento.createAt).toLocaleDateString() }}
                    </p>
                    <p>
                      {{  apontamento.note }}
                    </p>

                </div>
                </v-list-item>-->

                <v-list-item
              v-for="(apontamento, index) in apontamentos" :key='index'
              title="item"
              subtitle="SubItem..."
              class="ma-3 item-list"
              style="border-bottom: 1px solid rgba(172, 168, 168, 0.719); padding-bottom: 5px;">

              <div class="list-container">
                <v-avatar color="primary" height="45">
                  <span class="text-h5">{{ apontamento.createBy.split('@')[0].slice(0,1).toUpperCase() }}</span>
                </v-avatar>

                <div class="content-list">
                  <div class="info-container">
                    <p>{{  apontamento.createBy.split('@')[0] }} - <i>{{ new Date(apontamento.createAt).toLocaleDateString() }}</i></p>
                    <span>{{  apontamento.note }}</span>
                  </div>
                </div>

              </div>
              </v-list-item>

              </v-list>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </div>
    </v-card>

    <v-dialog v-model="actionParameterDialog"  width="75vw" persistent>
      <ActionDialog
        v-if="actionParameterDialog"
        :action="requestedAction"
        :entityId="entity.id || ''"
        :schema="schema"
        :entity="entity"
        @close="actionParameterDialog = false"
      >
      </ActionDialog>
    </v-dialog>
  </v-dialog>
</template>

<script>
import Dialog from "../log/Dialog.vue";
import ActionDialog from "./actionDialog.vue";
import entityFormPage from "../../views/entities/EntityFormPage.vue";
import entityFormDialogFullScreen from '@/components/dialogs/entityFormDialogFullScreen';
import DialogObsReprov from "../statusFlow/dialogObsReprov.vue";
import actionPermissionsMixins from '@/mixins/actionPermissionsMixins'
import { getActionByName, runAction } from "../../services/actionService";
export default {
  mixins: [actionPermissionsMixins],
  props: ["value", "schema", "entity", "actions", "loading", "isSave", "options", "apontamentos"],
  components: { entityFormPage, ActionDialog, Dialog, DialogObsReprov, entityFormDialogFullScreen },
  watch: {
    async value(v) {
      this.open = v;
      this.time = Object.values(this.schema.properties).filter(resp => resp.relationSchema !== undefined && resp.type == 'string').length
      //let time = Number(this.time + '000')
      if(this.open == true){
        if(this.entity.id !== undefined){
          setTimeout(() => {
            this.show = true;
          }, 1000)
        } else {
          this.show = true
        }
      } else {

        if (this.$route.params.schemaName == this.schema.title) {
          localStorage.setItem(
            "parents",
            JSON.stringify(Object.assign(this.entity))
          );
      }


        let afterSave = this.schema.afterSave;
        if(afterSave !== undefined && this.isSave == true){
          let entity = this.entityAux
          let action = await getActionByName(afterSave)

          console.log('ENTITY >>> ', Object.entries(entity))

          
          await runAction({ id: action?.id }, {  entity, 
            parents: JSON.parse(localStorage.getItem("parents")), }, id)
          
          location.reload()
        }

        this.show = false;

        if(this.$route.params.schemaName == this.schema.title){
          this.$cookies.set('filterSchema', '')
        }

        const id = this.$route.query.id
        window.location.href = window.location.href.replace(`?id=${id}`, '')
        this.schema.form.readyall = false
        this.aprove = false
      }
      this.count += 1
    },

    async entity(v, old){
      this.entityAux = old
      let id = v.id

      if(id && Object.values(this.schema.properties).filter(resp => resp.statusF).length > 0){
        let req = await this.$notificationService.verifyNotification(id)
        this.verifyNotification = req?.data?.data
        if(this.verifyNotification != null){
          if(this.verifyNotification.schemaRecordId !== null){
            this.aprove = this.verifyNotification.aprov
            let user = this.verifyNotification?.aprovEmail.filter(resp => resp.email === this.params?.username)
            if(user.length > 0){
              this.aproveUser = user[0].aprov
            } else {
              this.aproveUser = false
            }
          } else {
            this.aprove = true
          }
        } else {
          this.aprove = true
        }
      }
    }
  },
  data() {
    return {
      entityAux: null,
      statusFlow: null,
      aprovClick: null,
      verifyNotification: null,
      schemaRecordId: null,
      aprove: true,
      aproveUser: null,
      root: '',
      count: 0,
      active: false,
      open: false,
      actionParameterDialog: false,
      requestedAction: null,
      key: 0,
      descriptor: null,
      params: null,
      isValueLoading: null,
      show: false,
      time: 0,
      autoInc: null,
      position: null,
      actionsList: [],
      attrs: {
        class: 'mb-12',
        boilerplate: true,
        elevation: 2,
      },
      openObsDialog: false,
      width: screen.width
    };
  },

  computed: {
    schemaActions() {
      var actionId = []
      for(var i = 0; i < this.actionsList.length; i++){
        actionId.push(this.actionsList[i].actionId)
      }

      if (!this?.actions?.length) return [];
      else return this.actions.filter((action) => action.callType === "Schema" && actionId.indexOf(action.id) !== -1);
    },
    tabActions() {
      if (!this?.actions?.length) return [];
      else
        return this.actions.filter((action) => action.callType === "TabForm");
    },
    propertyActions() {
      if (!this?.actions?.length) return [];
      else
        return this.actions.filter(
          (action) => action.callType === "EventSchemaProperty"
        );
    },
  },
  async mounted() {
    this.notes = this.apontamentos
    console.log("NOTESTE >>> ", this.notes)
    this.time = Object.values(this.schema.properties).filter(resp => resp.relationSchema !== undefined && resp.type == 'string').length
    this.descriptor = this.schema.descriptor;

    this.params = await this.$auth.getUserParams();
    this.root = this.params.root;

    await this.getActionsPermission(this.params.userId)
    await this.getVerifyNotification()

    this.count += 1
    this.$forceUpdate()
  },
  methods: {

    verifyCampos(){
      if(Object.keys(this.entity).length == 0 || this.loading){
        this.show = true
        this.loading = true
      } else {
        this.show = false
        this.loading = false
      }
    },

    enviarNotfy(){
     /* if(Object.values(this.schema.properties).filter(resp => resp.statusF === true).length > 0){
        console.log('Enviar Notificação')
        this.socket.emit('sendNotification', {})
      }*/
    },

    getObsReprov(value){
      this.aprovarOrReprovar(false, value)
    },

    async getVerifyNotification(){
      const id = this.$route.query.id || this.entity.id

      if(id){
        let req = await this.$notificationService.verifyNotification(id)
        this.verifyNotification = req?.data?.data
        if(this.verifyNotification != null){
          if(this.verifyNotification.schemaRecordId !== null){
            this.aprove = this.verifyNotification.aprov
            let user = this.verifyNotification?.aprovEmail.filter(resp => resp.email === this.params?.username)
            if(user.length > 0){
              this.aproveUser = user[0].aprov
            }
          } else {
            this.aprove = true
          }
        } else {
          this.aprove = true
        }
      }
    },

    async aprovarOrReprovar(aprove, obsReprov){
      const id = this.$route.query.id || this.entity.id
      let obj = {
        schemaRecordId: id,
        aprov: aprove,
        obs: obsReprov || ''
      }

      await this.$notificationService.notificationUpdate(obj)
      await this.getVerifyNotification();

      this.aprovClick += 1
      window.location.reload()

//        this.socket.on('receiveNotification', async () => { })
    },


    requestRunSchemaAction(action) {
      this.requestedAction = action;
      this.actionParameterDialog = true;
    },

    close(e){
      this.active = e
    },

  },
};
</script>

<style>
.v-toolbar {
  flex: none;
}
@media (min-width: 768px) {
  .v-dialog__content {
    align-items: start;
    display: flex;
    height: 100%;
    justify-content: right;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
    width: 100%;
    z-index: 6;
    outline: none;
  }

  .my-custom-dialog {
    margin: 0;
    margin-right: 10px;
    height: 100%;
    width: 565px;
    max-height: 100% !important;
  }



  .my-custom-card {
    min-height: 100%;
  }
  .v-overlay,
  .v-overlay__scrim {
    background-color: transparent !important;
  }
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

.v-input {
  font-size: 12px;
}

.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px;
}
.v-icon.v-icon {
  font-size: 20px;
}

.v-placeholder {
  line-height: 10px !important;
}

.v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 9px;
}
.v-label {
  font-size: 12px;
}

.v-card > *:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  padding: 12px;
  border-bottom-right-radius: inherit;
  overflow-x: hidden; 
  position: absolut;
}

.list-container {
  display: flex;
}

.info-container {
  display: flex;
  margin: 5px;
  flex-direction: column;
}

.info-container p {
  margin: 0;
}

.info-container span {
  font-size: small;
  color: cornflowerblue;
}

.list-item {
  border-bottom: 1px solid rgba(172, 168, 168, 0.719);
}

.content-list {
  display: flex;
  justify-content: space-between;
  width: 390px;
  align-items: center;
}
</style>