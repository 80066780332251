import Vue from "vue";

export default {

    async insertStatus(obj){
        return await Vue.prototype.$http.post('StatusFlow/insert', obj);
    },

    async getBySchema(schemaName, field){
        return await Vue.prototype.$http.get('StatusFlow/getBySchemaAndField', { params: { schemaName, field }});
    },

    async  deletebySchema(id){
        return await Vue.prototype.$http.put('StatusFlow/deleteById', {
            params: {
                id
            }
        });
    }
}