<template>
  <v-dialog
    @click:outside="$emit('close')"
    v-model="dialog"
    scrollable
    content-class="my-custom-dialog"
  >
    <v-card>
      <v-app-bar flat color="transparent">
        <v-btn large icon @click="$emit('cancel')">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Usuários</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn :disabled="isLoading" large icon color="success" @click="salvar($event)">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container>
        <v-form ref="form">
          <v-text-field
            v-model="name"
            label="Nome"
            placeholder="Nome do usuário"
            :rules="requiredRule"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="email"
            label="E-mail"
            placeholder="E-mail do usuário"
            :rules="emailRules"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="telefone"
            label="Telefone"
            placeholder="Telefone do usuário"
            v-mask="'(##) #####-####'"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="password"
            label="Senha"
            type="password"
            placeholder="Senha do usuário"
            :rules="requiredPassword"
            outlined
            dense
          ></v-text-field>

          <v-text-field
            v-model="confirmPassword"
            label="Confirmar senha"
            type="password"
            placeholder="Confirmar senha"
            outlined
            dense
          ></v-text-field>

        <v-autocomplete
            v-model="groupId"
            :items="group"
            :loading="isLoading"
            :search-input.sync="search"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-database-search"
            label="Grupos"
            :rules="requiredRule"
            dense
            outlined
          ></v-autocomplete>

        <v-autocomplete
          v-model="compayIds"
          :search-input.sync="searchCompany"
          :multiple="true"
          :items="companies"
          label="Filial"
          dense
          outlined
          item-text="code"
          item-value="id"
          prepend-icon="mdi-database-search"
          chips
        >
        </v-autocomplete>
        
        <v-autocomplete
            v-model="principal"
            @focus="getPrincipalCompany"
            label="Principal"
            :search-input.sync="searchPrincipalCompany"
            type="text"
            placeholder="Principal"
            outlined
            dense
            :items="principalCompay"
            item-text="code"
            item-value="id"
        ></v-autocomplete>

        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import EntityService from '../../services/entityService';
export default {
  props: ["value", "saveOrUpdate", "user"],
  components: {},
  watch: {
    value(v) {
      this.dialog = v;
      if (!v) {
        this.tenanty = null,
        this.name = null,
        this.email = null,
        this.telefone = null,
        this.groupId = null,
        this.password = null,
        this.confirmPassword = null,

        this.resetValidation();
      } else {
        this.findGroups()
        this.isLoading = false
      }
    },

    search(){
        this.findGroups()
    },

    searchCompany(){
      this.getCompanies()
    },

    searchPrincipalCompany(){
     this.getPrincipalCompany() 
    }
  },
  data() {
    return {
      switch1: false,
      requiredRule: [(v) => !!v || "Campo obrigatório!"],
      requiredPassword: [(v) => !!v || "Campo obrigatório!", v => v?.length >= 6 || 
          'Precisa ter no minimo 6 caracteries', v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'A senha deve conter pelo menos uma letra minúscula, um número, um caractere especial @%$ e uma letra maiúscula',
          v => /(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#;$%*(){}_+^&]/.test(v) || "Faltou algum caraterie especial"],

      emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail inválido!"],

      valid: true,
      dialog: false,
      tenanty: null,
      name: null,
      email: null,
      telefone: null,
      groupId: null,
      password: null,
      confirmPassword: null,
      id: null,
      groups: [],
      group: [],
      search: '',
      isLoading: true,
      searchCompany: '',
      searchPrincipalCompany: '',
      compayIds: [],
      companies: [],
      principalCompay: [],
      principal: '',
      entityService: new EntityService("CompanySite")
    };
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    async findGroups() {
      let data = await this.$acessGroup.findAllGroup(this.search, 1, 10);
      this.groups = data.data.data.data;
      if (this.groups) {
        for (var i = 0; i < this.groups.length; i++) {
          this.group.push({
            name: this.groups[i].groupName,
            id: this.groups[i].id,
          });
        }
      }
    },

    async getCompanies(){
      let data = await this.entityService.getAll(1, 1000, 
        this.searchCompany ? [] : [ ...this.compayIds ], 'description,code');
      this.companies = data.data?.data?.data
    },

    async getPrincipalCompany(){

      if(this.compayIds.length > 0){
        let data = await this.entityService.getAll(1, 1000, '', 'description,code', [ ...this.compayIds ]);
        this.principalCompay = data.data.data.data
      }

    },

    async salvar() {
      if (this.validate()) {
        this.isLoading = false
        this.tenanty = this.getTenanty();
        //this.groupId = await this.$route.params.id;
        if (this.password === this.confirmPassword) {
          const req = await this.$auth.createUser(
            this.tenanty,
            this.email,
            this.name,
            this.telefone,
            this.groupId,
            this.password,
            this.confirmPassword,
            this.compayIds,
            this.principal
          );
          if (req.data.success == true) {
            this.$notfy.success("Usuário cadastrado com sucesso");
            this.$emit("save");

            this.tenanty = ""
            this.email = ""
            this.name = ""
            this.telefone = ""
            this.groupId = ""
            this.password = ""
            this.confirmPassword = ""
            
            this.isLoading = true
          }
        } else {
          this.$notfy.error("Senhas não conferem");
        }
      } else {
        this.$notfy.error("Algum campo não foi preenchido ou não foi preechido corretamente");
      }
    },

    getTenanty() {
      const lc = window.localStorage.getItem("user-login");
      const user = JSON.parse(lc);
      return user[0];
    },
  },

  mounted() {
    this.getCompanies()
    //this.getPrincipalCompany()
  },
};
</script>
<style scoped>
.v-toolbar {
  flex: none;
}
</style>
