<template>
	<v-card class="d-flex flex-column align-stretch" style="padding: 5px;">
		

		<v-app-bar flat color="transparent" height="30" >
			<v-toolbar-title class="title">{{ action.description }}</v-toolbar-title>

            <v-spacer></v-spacer>
			<v-btn icon large color="error" @click="close">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-app-bar>
		
		<template v-if="rawResult && rawResult.type === 'excel-xlsx'">
            <ActionXLSX @close="close" :result="rawResult"></ActionXLSX>
        </template>



		<template v-if="!executed">
			<template v-if="paramsRequired">
				<v-card-subtitle>Parâmetros</v-card-subtitle>
				<v-card-text>
					<ActionsParamsForm v-model="params" :parameters="action.parameters">
					</ActionsParamsForm>
				</v-card-text>
			</template>

			<v-card-actions>
				<v-btn color="error" @click="close">
					Cancelar
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="info" @click="run">
					Executar
				</v-btn>
			</v-card-actions>
		</template>

		<template v-if="loading">
			<v-progress-circular class="align-self-center mb-4" indeterminate>
			</v-progress-circular>
		</template>

		<template v-if="rawResult && rawResult.type === 'message'">
			<v-card-text class="black--text">
				{{ rawResult.message }}
			</v-card-text>
		</template>

		<template v-if="rawResult && rawResult.type === 'pdf'">
			<ActionPdfMake @close="close" :result="rawResult"> </ActionPdfMake>
		</template>

		<template v-if="rawResult && rawResult.type === 'modalCustom'">
			<ActionResultCustom
				@close="close"
				:result="rawResult"
				@run-action="requestNewAction"
			></ActionResultCustom>
		</template>

		<template v-if="rawResult && rawResult.type === 'EventSchemaProperty'">
			<ActionResultCustom
				@close="close"
				:result="rawResult"
				@run-action="requestNewAction"
			></ActionResultCustom>
		</template>

		<template v-if="rawResult && rawResult.type === 'grid'">
			<div class="div-btn">
				<v-tooltip left>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" color="red" @click="$emit('close')" style="height: 25px; min-width: 5px;padding: 0 16px;">X</v-btn>
					</template>
					Sair
				</v-tooltip>
			</div>
			<ActionResultGrid :result="rawResult"></ActionResultGrid>
		</template>

		<template v-if="rawResult && rawResult.type === 'excel'">
			<ActionExcel @close="close" :result="rawResult"></ActionExcel>
		</template>

		<template v-if="rawResult && rawResult.type === 'pivoTable'">
			<ActionPivoTable :result="rawResult" />
		</template>

		<v-dialog v-model="actionParameterDialog"  width="75vw" persistent>
			<ActionDialog
				v-if="actionParameterDialog"
				:action="requestedAction"
				@close="actionParameterDialog = false"
				:predefined-params="paramsToSend"
			>
			</ActionDialog>
		</v-dialog>
	</v-card>
</template>

<script>
	import {
		getActionById,
		getActionByName,
		runAction,
	} from "../../services/actionService";
	import ActionsParamsForm from "./actionsParamsForm.vue";
	import ActionResultCustom from "./actionResultCustom.vue";
	import ActionResultGrid from "./actionResultGrid.vue";
	import ActionPdfMake from "./actionPdfMake.vue";
	import ActionExcel from "./actionExcel.vue";
	import ActionXLSX from "./actionXLSX.vue";
	import EntityService from '../../services/entityService';
import ActionPivoTable from './actionPivoTable.vue';
	export default {
		name: "ActionDialog",
		props: {
			action: Object,
			entityId: String,
			predefinedParams: {
				type: Object,
				default: () => ({}),
			},
			schema: Object,
			entity: Object
		},

		components: {
			ActionsParamsForm,
			ActionResultCustom,
			ActionResultGrid,
			ActionPdfMake,
			ActionExcel,
			ActionXLSX,
			ActionPivoTable
		},

		data() {
			return {
				params: {
					...JSON.parse(JSON.stringify(this.predefinedParams)),
				},
				paramsRequired: false,
				paramsToSend: {},
				loading: false,
				rawResult: null,
				executed: false,

				actionParameterDialog: false,
				requestedAction: null,
			};
		},

		methods: {
			async pdfPrinter(definition, name) {
				var pdfMake = require("pdfmake/build/pdfmake.js");
				var pdfFonts = await require("pdfmake/build/vfs_fonts.js");
				pdfMake.vfs = pdfFonts.pdfMake.vfs;
				pdfMake.createPdf(definition).download(name);
				return await pdfMake;
			},

			async run() {
//				console.log("acao", this.action);
				this.executed = true;
				this.loading = true;

				if(this.entity) {

					let entityData = Object.assign(this.entity)
					let properties = Object.assign(this.schema.properties)
					let childrens = Object.entries(properties).filter(item => item[1].relationSchema && item[1].type == "string" )
					
					this.params.childrens = {}
					for(let i = 0; i < childrens.length; i++) {
						if(entityData[childrens[i][0]]){

							let entityService = new EntityService(childrens[i][1].relationSchema);
							let entity = await entityService.getById(entityData[childrens[i][0]])

							try {
								this.params.childrens[childrens[i][1].relationSchema] = Object.assign(entity.data.data)
							} catch(err){
								//
								console.log("Error ", err)
							}
						}
					}

					this.params.parents = JSON.parse(localStorage.getItem("parents"))
				}



				const result = await runAction(
					{ id: this.action.id },
					this.params,
					this.entityId,
					process.env.VUE_APP_API_URL
				);
				if (result?.type === "fullCustom") {
//					console.log('teste ', this.$root)
					this.$root.lastFullscreenActionResult = result;
					this.$router.push({ name: "ActionResult" });
					this.close();
				}
				this.loading = false;

				if (Object.keys(result).length) {
//					console.log('result ', result)
					this.rawResult = result;
				} else {
					this.rawResult = {
						type: "message",
						message: "Ação executada com sucesso. Não há retorno.",
					};
				}

				if(this.rawResult?.reloadPage){
					window.location.reload()
				}
			},

			async requestNewAction(identifier, params) {
//				console.log(params);
				let action;
				if (identifier.id) action = await getActionById(identifier.id);
				else if (identifier.name)
					action = await getActionByName(identifier.name);
				else throw new Error("Unable to request action without id or name");
				this.requestedAction = action;
				if (params && Object.keys(params).length) {
					this.paramsToSend = params;
				}
				this.actionParameterDialog = true;
			},

			close() {
				this.$emit("close");
			},
		},

		mounted() {
			if (this.action?.parameters?.length) this.paramsRequired = true;
			if (
				!this.paramsRequired &&
				["Separate", "TabForm", "FromAction", "Schema"].includes(
					this.action?.callType
				)
			)
				this.run();
		},
	};
</script>

<style></style>


<style scoped>
.div-btn {
width: 100%; text-align: end; padding: 5px;
}

</style>