import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import apiClient from "./api/apiClient";
import authService from "./services/authService";
import userAccess from "./services/userAccess";
import acessGroupService from "./services/acessGroupService";
import profileService from "./services/profileService";
import authAzureService from "./services/authAzureService";
import notificationStatusService from "./services/notificationStatusService"
import notificationService from "./services/notificationService";
import sheredSchemaRecordService from "./services/sheredSchemaRecordService";
import itemStageTreeEdit from "./components/itemStage/itemStageTreeEdit";
import itemCaracEdit from "./components/itemCarac/itemCaracEdit";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

import VuetifyConfirm from "vuetify-confirm";
import viewService from "./services/viewService";
import weeklyScheduleService from "./services/weeklyScheduleService";


Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "Confirmar",
  buttonFalseText: "Cancelar",
});

import VueNativeNotification from 'vue-native-notification'
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import VueMask from "v-mask";
Vue.use(VueMask);
/*
import money from 'v-money'
Vue.use(money)*/

import VuetifyMoney from "vuetify-money";
Vue.use(VuetifyMoney);

import NProgress from "vue-nprogress";
Vue.use(NProgress);
const nprogress = new NProgress();

import VueLocalForage from "vue-localforage";
Vue.use(VueLocalForage);

import Notifications from "vue-notification";
Vue.use(Notifications);

Vue.prototype.$loading = function (isLoading) {
  isLoading ? nprogress.start() : nprogress.done();
  Vue.prototype.$valueLoading = isLoading
  //console.log('isLoading ', isLoading)
};


Vue.prototype.$auth = authService;
Vue.prototype.$http = apiClient.createHttp();
Vue.prototype.$notfy = notificationService;

Vue.prototype.$acessGroup = acessGroupService;
Vue.prototype.$profile = profileService;
Vue.prototype.$authAzure = authAzureService;
Vue.prototype.$access = userAccess;
Vue.prototype.$notificationService = notificationStatusService;
Vue.prototype.$sheredSchemaRecord = sheredSchemaRecordService;
Vue.prototype.$viewService = viewService
Vue.prototype.$weeklyScheduleService = weeklyScheduleService

Vue.config.productionTip = false;

Vue.prototype.$isConfirm = async function () {
  return await Vue.prototype.$confirm(
    "Tem certeza ?"
  );
};

Vue.prototype.$isConfirmGroupDelete = async function (msg, item) {
  return await Vue.prototype.$confirm(
    `${msg} "${item}"?`
  );
};

Vue.prototype.$isMessage = async function (msg, item) {
  return await Vue.prototype.$confirm(
    `${msg} ${item}`
  );
};

Vue.prototype.$pdfPrinter = async function () {
  const doc = new jsPDF()
  autoTable(doc, { html: 'table' })
  doc.save('table.pdf')
  return doc
};

Vue.component("item-stage-tree-edit", itemStageTreeEdit);
Vue.component("item-carac-edit", itemCaracEdit);

//Vue.config.productionTip = false;


new Vue({
  router,
  vuetify,
  nprogress,
  data() {
    return {
      lastFullscreenActionResult: null,
    };
  },
  render: (h) => h(App),
}).$mount("#app");
