<template>
  <v-row justify="space-around" >
      <v-dialog
       @click:outside="$emit('OK')"
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="900"
      >
     <v-card>
        <v-container fluid :key="componentKey">
            <form-table
                :schemaName="schemaName"
                :ids="array"
                :item="array"
                :open="value"
                :addItem="true"
                :objPagination="paginationConfig"
                relation
                @searchForm="searchForm"
                @itemSelect="slectItem"
                @pagina="paginaReq"
                @properties="getProperties"
                @input-ids="
                ($event) => {
                array = $event;
                $forceUpdate();
                }"
            >
            </form-table>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$emit('OK')"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>

      </v-dialog>
  </v-row>
</template>

<script>
import FormTable from '../table/FormTable.vue';
import EntityService from "../../services/entityService";
import emitter from "@/services/emitter";
//import { runAction, getSchemaActions } from "../../services/actionService";
export default {
    name: 'RelationLinkDialog',
    props: ['value', 'schemaName', 'relationCondition', 'filter', 
    'filterSchema', 'property', 'relationField', 
    'searchFields', 'companySiteIds', 'objFilter'],
    components: {
        FormTable
    },
    data () {
      return {
          entityService: new EntityService(this.schemaName),
          dialog: false,
          array: [],
          componentKey: 0,
          paginationConfig: {},
          page: 1,
          itemSelect: null,
          propertyActions: [],
          search: "",
          objFilterIds: []
      }
    },

    async created(){

      document.addEventListener('keyup', this.onEsc)
      this.paginationConfig = this.pagination
    },

    beforeDestroy() {
      document.removeEventListener('keyup', this.onEsc)
    },

    watch: {
        async value(v){
            this.dialog = v
            if(this.dialog == true){
              await this.getIds(this.page, this.search)
            }

            this.search = ""
            this.componentKey += 1;
        },

        page(p){
          this.getIds(p, this.search)
        },

    },

    methods: {


      async searchForm(value){
        this.search = value
        await this.getIds(1)
      },

      getProperties(values){
        this.$nextTick(() => {
          this.propertyActions = values;
        })
      },

      async searchObjFilter(value){
        let arrayFilter = this.objFilter.arrayFilter
        let ids = []
        let schemaField = []

        for(let i = 0; i < arrayFilter.length; i ++){

          let entityService = new EntityService(arrayFilter[i].schema);
          let res = await entityService.searchAutoComplete(arrayFilter[i].schemaFilter[0], 
            arrayFilter[i].schemaFilter[1], this.companySiteIds);

          if(!res.data.success || res.data.data.data.length == 0) return;
          
          ids.push(res.data.data.data.map((item) => {
            return item['id'];
          }).join(','))

          schemaField.push(arrayFilter[i].schemaField)

        }



        let newIds = ids.map((item) => {
          return `[${item}]`
        }).join(',')

        let newschemaField = schemaField.map((item) => {
          return `[${item}]`
        }).join(',')

        let newCondition
        if(value == ''){
          newCondition = newIds
        } else {
          newCondition = `${newIds},[${value}]`
        }

        return {
          newField: `${newschemaField},${this.objFilter.searchFields}`,
          newCondition
        }       

      },

        paginaReq(pagina){
          this.page = pagina
        },
        async getIds(page){
          let data
          let condition = ''
          let field = ''
          let mode = "Contains"
          if(this.relationCondition != undefined){
            field = this.relationCondition.field
            let conditionRelation = this.relationCondition.condition.slice(1)
            condition = window.localStorage.getItem(`relationLink-${conditionRelation}`)


          }

          if(this.filter != undefined){
            condition = this.filter[1]//.replace(/\[/g, "").replace(/]/g, "")
            field = this.filter[0]//.replace(/\[/g, "").replace(/]/g, "").split(',')[0]

            //mode = "Equals"
          }

          if(this.objFilter != undefined){

            const {newField, newCondition} = await this.searchObjFilter('')

            field = newField
            condition = newCondition


          }

          if(!this.search.length){

            // RELATION FIELD
            if(localStorage.getItem(this.schemaName) !== null){
              let idsItems = localStorage.getItem(this.schemaName).split(",").toString()
              if(idsItems){
                data = await this.entityService.search(null, null, page, 30, idsItems, 'Contains', this.companySiteIds)
              }
            } else {

              if(this.filter != undefined || this.objFilter != undefined){
                data = await this.entityService.getAllByIds(page, 30, `${condition},[]`, `${field}`, mode) 
              } else {
                data = await this.entityService.getAllByIds(page, 30, `${condition}`, `${field}`, mode) 
              }
               
            }

          } else {
            let ids = null
            let filter = ''
            let search = `${this.search}`
            
            if(condition != '' && this.filter != undefined){
              search = `${condition},[${this.search}]`
            } 

            if(condition != '' && this.relationCondition != undefined){
              search = `[${condition}],[${this.search}]`
            }

            if(this.filter !== undefined){
              filter = this.filter[0].split(",").map((item) => {
                return `[${item}]`
              }).join(',')
              
            } if(field.length > 0 && this.relationCondition != undefined) {
                filter = `[${field}],[${this.searchFields}]`
                //filter = `${field}`
              } 

              
             else if(field.length > 0 && this.filter != undefined) {
                filter = `${field}`
              }
              else if(this.objFilter != undefined){

                const {newField, newCondition} = await this.searchObjFilter(search)

                filter = newField
                let newSearch = newCondition

                search = newSearch

            }
              
            else {
              filter = this.searchFields
            }

            // RELATION FIELD
            if(localStorage.getItem(this.schemaName)){
              ids = localStorage.getItem(this.schemaName).split(",").toString()
            } 

            data = await this.entityService.search(filter, search, page, 30, ids, "Contains", this.companySiteIds)
          }

          let dataArray = data?.data?.data?.data
          this.paginationConfig = { pages: data?.data?.data?.pages, page: data?.data?.data?.page }


          if(this.filterSchema !== undefined && this.filterSchema.schemaFinal == this.schemaName){
            let filtro = this.$cookies.get('filterSchema')
            let entityService = new EntityService(this.filterSchema.schemaContentIds)
          

            let req = await entityService.search(null, null, 1, 30, filtro)
            let items = req?.data?.data?.data

            let ids = []
            for(let i = 0; i < items.length; i++){
              ids.push(items[i]?.itemId)
            }

            dataArray = dataArray.filter(resp => ids.indexOf(resp.id) !== -1)
            this.paginationConfig = { pages: 1, page: 1 }
          }

          this.array = []
          for(let i = 0; i < dataArray?.length; i++){
            this.array.push(dataArray[i]?.id)
          }
        },

        async slectItem(item){
          window.localStorage.setItem(`relationLink-${this.property}`, item.id)
          this.itemSelect = item
          this.dialog = false
          this.$emit('item', item)

          emitter.emit('eventAction', this.property);

          if(this.relationField){
            localStorage.setItem(`relation-field-${this.relationField.schema}`,  item[this.relationField.ids])
          }
          if(this.filterSchema !== undefined){
              if(this.filterSchema.schemaInicial == this.schemaName){
                this.$cookies.set("filterSchema", item[this.filterSchema.ids]);
              }
          }
        },

        onEsc(e) {
          if (!e) e = window.event
          let keyCode = e.keyCode || e.which
          if (keyCode == '27') {
            this.$emit('OK')
          }
        }
    }
}
</script>