<template>
    <div>
        <entity-form-dialog
            v-if="schemaProp"
            v-model="formDialog"
            :schema="schemaProp"
            :form="schemaProp.form"
            :entity="itemSelected"
            :actions="customActions"
            :value="formDialog"
            :loading="loading"
            @confirm="saveItem"
            @delete="deleteItem"
            @cancel="cancelFormDialog"
            @dialog="($event) => (formDialog = $event)"
        ></entity-form-dialog>
    </div>
</template>

<script>
import entityPageMixin from "../../mixins/entityPageMixin";

export default {
    components: {
        EntityFormDialog: () => import("../dialogs/entityFormDialog.vue")
    },
    mixins: [entityPageMixin],
    props: ["schemaName", "schemaProp", "open"],
    data(){
        return {
            formDialog: false,
        }
    },

    mounted(){
        this.formDialog = true
    },

    watch: {
        formDialog(v){
            this.formDialog = v
            this.$emit('close', this.formDialog)
            this.$forceUpdate()
        },
    }
}
</script>