<template>
  <div>
    <div class="text-h4 mb-10">
      Usuários
    </div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-on="on" v-bind="attrs" @click="$router.push('/grupos')" icon dark>
        <v-icon>mdi-account-group</v-icon></v-btn>
      </template>
      <span>Grupos</span>
    </v-tooltip>
    <!--
    <v-btn @click="backToGroupList" color="primary" icon dark>
      <v-icon>mdi-arrow-left</v-icon></v-btn
    >--->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-on="on" v-bind="attrs" @click="openUserDialog" icon dark>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      </template>
      <span>Cadastrar Usuário</span>
    </v-tooltip>
<!--
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-on="on" v-bind="attrs" @click="openPermissionDashDialog" icon dark>
        <v-icon>mdi-finance</v-icon>
      </v-btn>
      </template>
      <span>Dashboards</span>
    </v-tooltip>-->

    <form @submit.prevent="searchItems">
      <v-text-field
        class="pt-5 pb-5"
        chips
        v-model="search"
        clearable
        hide-details
        hide-selected
        prepend-icon="mdi-magnify"
        label="Search"
        solo
      />
    </form>

    <v-data-table
      :headers="headers"
      :items="names"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      class="elevation-1"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <!--@click:row="openPermissionsDialog" --->

      <template v-slot:item.actions="{ item }">

          <v-menu
            bottom
            left
            
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                color="black"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">

                <v-list-item-title @click="openPermissionsDialog(item)">
                  <span>Permissões</span>
                </v-list-item-title>

                </v-list-item>

              <v-list-item style="cursor: pointer">
                <v-list-item-title @click="openUserDialogUpdate(item, item.id)">
                  <span>Editar Usuário</span>
              </v-list-item-title>
              </v-list-item>
<!---
              <v-list-item style="cursor: pointer">
                <v-list-item-title @click="openUpdateGroup(item, item.id)">
                  <span>Editar Grupo</span>
              </v-list-item-title>
              </v-list-item>
--->
              <v-list-item style="cursor: pointer">
                <v-list-item-title @click="deleteUser(item, item.id)">
                  <span>Deletar Usuário</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item style="cursor: pointer">
                <v-list-item-title @click="updatePasswordUser(item)">
                  <span>Alterar senha do usuário</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item style="cursor: pointer">
                <v-list-item-title @click="externalToken(item.id)">
                  <span>Token View</span>
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
          
      </template>
    </v-data-table>

    <div class="text-center pt-2 mb-10">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="inputPagination"
      ></v-pagination>
    </div>

    <!-- <h1>Controle de acesso</h1> -->
    <permissions-dialog
      :value="permissionsDialog"
      :user="selected_user"
      :group_id="selected_user.groupId"
      @cancel="cancelPermissionsDialog"
      @save="onSaveRequest"
    />

    <user-dialog
      v-model="userDialog"
      :value="userDialog"
      :saveOrUpdate="saveOrUpdate"
      :user="user"
      @close="closeUserDialog"
      @cancel="cancelUserDialog"
      @save="onSaveUserRequest"
    />

    <user-update-dialog
      v-model="userUpdateDialog"
      :value="userUpdateDialog"
      :user="user"
      @close="closeUserUpdateDialog"
      @cancel="cancelUserUpdateDialog"
      @updated="onUpdateUserSuccess"
    />
    
    <update-group-dialog
      v-model="updateGroupDialog"
      :value="updateGroupDialog"
      :user="user"
      @close="closeUpdateGroup"
      @cancel="cancelUpdateGroup"
      @updated="onUpdateGroupSuccess"
    />

    <update-password-admin
      v-model="updatePasswordAdminDialog"
      :value="updatePasswordAdminDialog"
      :user="user"
      @close="closeUpdatePasswordAdmin"
      @cancel="cancelUpdateAdmin"
      @updated="onUpdatePasswordSuccess"
    />

    <external-token-modal 
      v-model="externalTokenDialog"
      :value="externalTokenDialog"
      :tokenAccess="externalTokenMessage"
      :userId="userIdForExternalToken"
      @close="externalTokenDialog = false"
      @cancel="externalTokenDialog = false"
    />
<!--
    <permission-dashboard-dialog 
      :inputPagination="inputPagination"
      :pageCount="pageCount"
      :users="getUsersByTenanty"
      :value="permissionDashDialog"
      v-model="permissionDashDialog"
      @cancel="permissionDashDialog = false"
      @close="permissionDashDialog = false"
    />-->


  </div>
</template>

<script>
// import ControlAcess from "../../components/card/ControlAcess.vue";
import PermissionsDialog from "../../components/dialogs/permissionsDialog.vue";
import UserDialog from "../../components/dialogs/usersDialog.vue";
import UserUpdateDialog from "../../components/dialogs/usersDialogUpdate.vue";
import UpdateGroupDialog from '../../components/dialogs/updateGroupDialog.vue';
import UpdatePasswordAdmin from "../../components/dialogs/updatePasswordAdmin.vue";
//import PermissionDashboardDialog from "../../components/dialogs/permissionDashboardDialog.vue";

import ExternalTokenModal from '@/components/externalToken/ExternalTokenModal'

export default {
  components: { PermissionsDialog, UserDialog, UserUpdateDialog, UpdateGroupDialog, UpdatePasswordAdmin, ExternalTokenModal },

  methods: {
    backToGroupList() {
      this.$router.push("/userPage");
    },
    openPermissionsDialog(user) {
      this.selected_user = user;
      this.permissionsDialog = true;
      //console.log(user)
    },
    onSaveRequest() {
      console.log("Save request");
      this.permissionsDialog = false;
    },
    async cancelPermissionsDialog() {
      if (await this.$isConfirm()) this.permissionsDialog = false;
    },

    openPermissionDashDialog(){
      this.permissionDashDialog = true;
    },

    openUserDialog() {
      this.userDialog = true;
      this.saveOrUpdate = true;
    },

    async openUserDialogUpdate(item) {
     await this.findUser(item.id).then(resp => {
        this.user = resp
      });
      console.log("user", this.user)
      this.userUpdateDialog = true;
    },

    openUpdateGroup(item){
      this.user = item;
      this.updateGroupDialog = true;
    },

    onUpdateGroupSuccess(){
      this.updateGroupDialog = false;
      this.getUsersByTenanty();
    },

    closeUpdateGroup(){
      this.updateGroupDialog = false;
    },

    async cancelUpdateGroup(){
      if (await this.$isConfirm()) this.updateGroupDialog = false;
    },

    async updatePasswordUser(item){
     await this.findUser(item.id).then(resp => {
        this.user = resp
      });
      this.updatePasswordAdminDialog = true;
    },

    async cancelUpdateAdmin(){
      if (await this.$isConfirm()) this.updatePasswordAdminDialog = false;
    },

    closeUpdatePasswordAdmin(){
      this.updatePasswordAdminDialog = false;
    },

    onUpdatePasswordSuccess(){
      this.updatePasswordAdminDialog = false;
    },

    onSaveUserRequest() {
      this.getUsersByTenanty();
      this.userDialog = false;
    },

    async cancelUserDialog() {
      if (await this.$isConfirm()) this.userDialog = false;
    },

      async closeUserDialog() {
      this.userDialog = false;
    },

    async deleteUser(item, id){

      const data  = await this.$auth.getUserParams();
      let tenanty = data.tenanty;

      if (await this.$isConfirmGroupDelete("Tem certeza que deseja desativar o usuário", item.name))
        try {
          const req = await this.$auth.deleteUserById(id, tenanty, item.name, item.telefone, item.email, this.$route.params.id)
          console.log(req);
          if(req.data.success == true){
            this.$notfy.success("Usuário desativado");
            window.location.reload()
          }
          this.getUsersByTenanty();
        } catch (error) {
          //
        }
    },

    onUpdateUserSuccess() {
      this.getUsersByTenanty();
      this.userUpdateDialog = false;
    },

    async cancelUserUpdateDialog() {
      if (await this.$isConfirm()) this.userUpdateDialog = false;
    },

    async closeUserUpdateDialog() {
      this.userUpdateDialog = false;
    },

    async getUsersByTenanty(page, limit, search) {
      try {
        const data = await this.$acessGroup.getUsersByTenanty(page, limit, search);

      if(data != undefined){
        this.itemsPerPage = data.data.limit;
        this.pageCount = data.data.pages;

        this.users = data.data.data;
        
        if (data.success && this.users instanceof Array) this.names = [];
        this.users
          .filter((user) => user !== null && user.isDeleted == false)
          .forEach((user) => {
            this.names.push({
              id: user.id,
              name: user.name,
              telefone: user.telefone,
              email: user.userName,
              groupId: user.groupId
            });
          });
          return this.users;
      } /*else {
        this.$notfy.error("Grupo não possui usuários")
        this.users = []
        return this.users
      }*/
      
      } catch(err){ 
        this.$notfy.error(err)
      }
    },

    async inputPagination(page){
      const data = await this.getUsersByTenanty(page)
      window.scrollTo(0, 0);
      return data
    },

    async getGroupName(id){
      let data = await this.$acessGroup.permissionGroupById(id);
      let groupName = data.data.data.groupName
      return groupName
    },

    async searchItems() {
      let data

      if(this.search.length > 0){
        data = await this.getUsersByTenanty(1, 10, this.search)
      } else {
        data = await this.getUsersByTenanty()
      }
      return data
    },

    async findUser(id){
      const req = await this.$auth.findById(id);
      return req.data.data;
    },

    async externalToken(id){

      this.userIdForExternalToken = id
      this.externalTokenDialog = true
    }
  },

  async mounted() {
    await this.getUsersByTenanty();
  },

  computed: {
  },
  data: () => ({
    names: [],
    user: {},
    saveOrUpdate: Boolean,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 0,
    /*    items: [
      {
        id: "15454",
        name: "Wellinton",
        email: "wellinton.reis@accist.com.br",
      },
      {
        id: "87854",
        name: "Gabriel",
        email: "gabriel@accist.com.br",
      },
    ],*/
    headers: [
      /*      {
        text: "Código",
        value: "id",
      },*/
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "E-mail",
        value: "email",
      },/*
      {
        text: "Grupo",
        value: "gorupName"
      },*/
      {
        text: "Actions",
        value: "actions",
      },
    ],

    selected_user: {},
    permissionsDialog: false,
    userDialog: false,

    userUpdateDialog: false,

    updateGroupDialog: false,
    updatePasswordAdminDialog: false,

    externalTokenDialog: false,
    externalTokenMessage: null,
    userIdForExternalToken: null,
    permissionDashDialog: false
  }),
};
</script>
