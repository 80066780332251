<template>

  <v-dialog
    @click:outside="$emit('close')"
    v-model="dialog"
    scrollable
    max-width="600px"
  >

      <template v-slot:activator="{ on, attrs }">
        <v-col md="5">
          <!--
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Copiar Acesso
        </v-btn>--->
        <v-list-item v-on="on" v-bind="attrs">
            Copiar acesso
        </v-list-item>

        </v-col>

      </template>

    <v-card>
      <v-app-bar flat color="transparent">
        <v-btn large icon @click="dialog = false">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Copiar acesso</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn large icon color="success" @click="copyAccess()">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container>
        <v-form ref="form">

<!--        
        <v-select
            v-model="select"
            :items="names"
            item-text="email"
            item-value="id"
            label="Usuários"
            dense
            outlined
          ></v-select>
 -->
        <v-autocomplete
            v-model="select"
            :items="names"
            :search-input.sync="search"
            item-text="name"
            item-value="id"
            prepend-icon="mdi-database-search"
            label="Usuários"
            dense
            outlined
          ></v-autocomplete>

        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: ["userId"],
    data() {
        return {
            dialog: false,
            select: "",
            names: [],
            groupId: null,

            search: ""
        }
    },

    watch: {
      search(){
        this.findAllPermission()
      }
    },

    methods: {
        
        async copyAccess(){
            if(this.select != ""){
                console.log("userId", this.userId)
                console.log(this.select)

                const req = await this.$acessGroup.copyPermissions(this.select, this.userId);
                console.log(req);

                if(req.data.success == true){
                    window.location.reload()
                }
            }
            this.select = "";
            this.dialog = false;
        },

        async findAllPermission() {
            this.groupId = this.$route.params.id;

            const data = await this.$acessGroup.findAllPermissiosByGroupId(this.search, this.groupId, 1, 5);
            

            this.users = data.data.data;

            if (data.success && this.users instanceof Array) this.names = [];
            this.users
                .filter((user) => user !== null)
                .forEach((user) => {
                this.names.push({
                    id: user.userID,
                    name: user.name,
                    email: user.email,
                });
            });
          },
    },

    mounted() {
        this.findAllPermission()
    }

}
</script>