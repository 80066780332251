<template>
  <v-app>
    <notifications />
    <nprogress-container></nprogress-container>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
export default {
  name: "App",
  components: { NprogressContainer },

  data: () => ({
    //
  }),
  mounted() {
    document.title = "Simjob";

    let href = location.href?.split('/s');
    if(href.length > 1){
      let s = href[1].split('/#/')
      let newHref = `${href[0]}/#/s${s[0].replace('#/', '')}`

      let url = new URLSearchParams(s[0].replace('?', ''))

      location.href = newHref

      this.$router.push(`/s?v=${url.get('v')}&t=${url.get('t')}`);
    }

  },

  async beforeMount(){
    const data = await this.$auth.getUserToken();
    if(!data) this.$router.push('/', {});
  },

  methods: {
  }
};
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
</style>