<template>
  <v-container fluid>
    <v-app>
      <v-app-bar app :color="colorNav == '' ? 'white' : colorNav" flat dark>
        <v-list-item-title class="text-h6">
          {{ nomeEmpresa == '' ? "Simjob" : nomeEmpresa }}
        </v-list-item-title>
        <!--
      <v-list-item-title class="text-h6" v-else>
        {{ nomeEmpresa }}
      </v-list-item-title> -->
        <!---
      <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="margin-right: 5px;" icon v-bind="attrs" v-on="on" class="tooltip">
              <v-avatar>
                <img
                :src="require('/src/assets/timeline-icon.png')"
                style=" height:30px; width: 30px;"
                >
                <v-icon style="position: absolute; height: 15px; width: 15px;  font-size: 15px;  bottom: 10px;
                right: 0;"> mdi-arrow-down-drop-circle</v-icon>
              </v-avatar>
              <span class="tooltiptext">Timeline</span>
            </v-btn>
          </template>
        
        <TimelineCard />

      </v-menu> 
--->
        <v-select class="mt-4 mr-5" :items="[ ...codeCompanySite, 'Mostrar Todos']"
          v-model="companySelected"></v-select>
        <MenuStatusFlow />
        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="tooltip">
              <v-avatar>
                <img :src="logo == '' ? require('/src/assets/logo-simjob.png') : logo"
                  style=" height:30px; width: 30px;">
                <v-icon style="position: absolute; height: 15px; width: 15px;  font-size: 15px;  bottom: 10px;
              right: 0;"> mdi-arrow-down-drop-circle</v-icon>
              </v-avatar>
              <span class="tooltiptext">Menu</span>
            </v-btn>
          </template>

          <v-list style="cursor: pointer">
            <v-list-item>
              <v-list-item-title @click="sendLogoff()">Logoff</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="perfilPage()">Perfíl de usuário</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="trocarPasswordPage()">Trocar de senha</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="root == true">
              <v-list-item-title @click="userPage()">Controle de acesso</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="root == true">
              <v-list-item-title @click="accountProfile()">Conta da empresa</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="configAuthPage()">Configuração de autenticação</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="root == true">
              <v-list-item-title @click="$router.push('/customization')">Personalização</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item>
              <v-list-item-title @click="$router.push('/weeklySchedule')">Programação Semanal</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer app permanent expand-on-hover class="navbar-scroll">
        <v-list nav dense>
          <v-col cols="12" align="center">
            <v-img :src="logo == '' ? require('/src/assets/logo-simjob.png') : logo"
              style="max-width:200px; max-height:200px; object-fit: contain;" />
            <v-text-field class="pt-4 pb-2" label="Pesquisar" v-model="search" />
          </v-col>

          <v-list-item link @click="$router.push('/weeklySchedule')">
            <v-list-item-icon>
              <v-icon>
                mdi-circle
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
             Dash Programação Semanal
            </v-list-item-title>
          </v-list-item>

          <div v-for="item in analysis" :key="item.id">
            <v-list-item style="cursor: pointer;" link @click="analysisNavigate(item)">
              <v-list-item-icon>
                <v-icon>mdi-view-parallel-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>

            </v-list-item>
          </div>

          <div v-for="(item, index) in filteredItems" :key="index">
            <v-list-item v-if="item._type === 'action'" link @click="requestRunSeparateAction(item)">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.description }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item._type === 'module'" link @click="entityNavigate(item.title)">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </div>
          <SobreDialog />
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <router-view></router-view>
      </v-main>

      <v-dialog v-model="actionParameterDialog" width="90vw" persistent>
        <ActionDialog v-if="actionParameterDialog" :action="requestedAction" @close="actionParameterDialog = false">
        </ActionDialog>
      </v-dialog>
    </v-app>
  </v-container>
</template>

<script>
import authService from "../services/authService";
import { getSeparateActions } from "../services/actionService"
//import TimelineCard from "../components/timeline/TimelineCard";
import ActionDialog from "../components/dialogs/actionDialog.vue"
import SobreDialog from "../components/dialogs/SobreDialog.vue";
import MenuStatusFlow from "../components/statusFlow/menuStatusFlow.vue";
import { PublicClientApplication } from '@azure/msal-browser';
import EntityService from '../services/entityService';
//import { notify } from "../services/nativeNotificationService";

import emitter from '@/services/emitter'

export default {
  name: "Home",
  data() {
    return {
      timelineDialog: false,
      separateActions: [],
      modules: [],
      search: "",
      actionParameterDialog: false,
      requestedAction: null,
      showDailog: false,
      root: null,
      actionsList: [],

      nomeEmpresa: '',
      colorNav: '',
      logo: '',
      entityService: new EntityService('Analysis'),
      analysis: [],
      companySite: [],
      codeCompanySite: [],
      isMultiCompany: false,
      companySelected: ''
    };
  },

  computed: {
    filteredItems() {
      var separateActions = [...this.separateActions];
      const modules = [...this.modules];


      var actionId = []
      for(var i = 0; i < this.actionsList.length; i++){
        actionId.push(this.actionsList[i].actionId)
      }
      
      separateActions = separateActions.filter(resp => actionId.indexOf(resp.id) !== -1)
      separateActions?.sort(function (a, b) {
          if (a?.name > b?.name) {
            return 1;
          }
          if (a?.name < b?.name) {
            return -1;
          }
          return 0;
        });
      modules.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      let items = [...separateActions, ...modules];
      items = items.filter((item) => {
        return (
          item?.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });

      items = items.filter((item) => {
        if (this.selected == null) return item;
        return item.isActive === this.selected;
      });

      items.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });

      items = items.filter(item => item._type == 'action' || item.permissions.indexOf("Menu") >= 0)
      
      return items;
    },
  },

  components: {
    ActionDialog,
    SobreDialog,
    MenuStatusFlow
    //TimelineCard
  },

  methods: {

    async getcompanySiteIds(){
      let items = []
      let entityService = new EntityService('CompanySite')
      let dataUser = await this.$auth.getUserParams()

      if(dataUser.companySite !== null){
        items = dataUser.companySiteIds?.split(',')
      } 

      if(dataUser.companySiteIdDefault){
        items.push(dataUser.companySiteIdDefault)
      }

      if(items?.length > 0){
        let data = await entityService.getAll(1, 100, null, null, items, null)
        this.companySite = data.data.data.data
        
        this.codeCompanySite = this.companySite.map((resp) => {

          if(resp.id === dataUser?.companySiteIdDefault) this.companySelected = resp.code

          return resp.code
        })
      }
    },
    
    async getAnalysis(){
      try{
        const req = await this.entityService.getAll(1, 30, this.search, '', '')
        return req.data.data.data;
      } catch(err){
        console.log("Error >>> ", err)
      }

    },

    perfilPage(){
      this.$router.push("/perfilUser", () => {})
    },

    accountProfile(){
      this.$router.push(`/accountProfile`, () => {})
    },

    trocarPasswordPage(){
      this.$router.push("/updatePassword", () => {})
    },

    async configAuthPage(){
      this.$router.push(`/configAuth/`, () => {});
    },

    userPage(){
      this.$router.push("/userPage", () => {})
    },

    entityNavigate(name) {
      this.$router.push({ name :'Entity', params: { schemaName : name}}, () => {})
      this.$forceUpdate();
    },

    analysisNavigate(item){
      this.$router.push({ name :'PivoTableView', params: { viewId: item.viewId, viewName: item.name }}, () => {});
      this.$forceUpdate();
    },

    requestRunSeparateAction (action) {
      this.requestedAction = action
      this.actionParameterDialog = true
    },

     async sendLogoff() {
        await this.$auth.removeUserParams();

        window.localStorage.removeItem("logo")
        window.localStorage.removeItem("nomeEmpresa")
        window.localStorage.removeItem("color")
        window.localStorage.removeItem("dominio")

        const homeAccountId = sessionStorage.getItem('homeAccountId');
        if(homeAccountId != null){
          this.logoofAzure(homeAccountId);
          //sessionStorage.setItem('homeAccountId', null);
          sessionStorage.clear();
        } else {
          this.$router.push("/");
        }
        
  },

  async verifyfirstAcccess(){
    const data = await this.$auth.getUserParams()
    const firstAccess = data.firstLogin
    if(firstAccess == true){
        this.$router.push("/firstAccess");
      }
  },

  logoofAzure(homeAccountId){
    const url = window.location.href.split("/#/")
    const redirectUri = url[0]

    const config = {
      auth: {
        clientId: '914ae50f-6a4c-49fe-b8ab-22264cd9abff',
        redirectUri: redirectUri,
        postLogoutRedirectUri: redirectUri
      }
    }
    
    const myMsal = new PublicClientApplication(config);

    const logoutRequest = {
      account: myMsal.getAccountByHomeId(homeAccountId),
    }

    myMsal.logoutRedirect(logoutRequest);
  }

  },

  async created(){
    //notify()
    this.nomeEmpresa = window.localStorage.getItem("nomeEmpresa");
    this.colorNav = window.localStorage.getItem("color");
    this.logo = window.localStorage.getItem("logo");

    await this.verifyfirstAcccess();
    this.analysis = await this.getAnalysis();

    await this.getcompanySiteIds()

  },

  async mounted() {
    const params = await authService.getUserParams();
    this.root = params.root;

    let href = window.location.href.split('/s');

    if(href.length > 0){
      //
    }

    const userId = params.userId
    const actionsData = await this.$acessGroup.findPermissionsByUserId(userId);
    let schemas = actionsData.data?.schemas
    const actionsUser = schemas.filter(item => item.actions?.length > 0)
    actionsUser?.map((x) => {
      x.actions?.map((y) => {
        //console.log('y ', y)
        this.actionsList.push(y)
      })    
    })

    if (params.modules) {
      this.modules = params.modules.map(mdl => {
        return {
          ...mdl,
          _type: 'module',
        }
      });
    }

    
    const separateActions = await getSeparateActions();
    if (separateActions) {
      this.separateActions = separateActions.map(action => {
        return {
          ...action,
          _type: 'action',
        }
      })
    }
    
    await emitter.on('evenWatchSchema', async (schema) => {
      if(schema.multi_company){
        this.isMultiCompany = true
      } else {
        this.isMultiCompany = false
      }
    })
  },

  watch: {
    companySelected(value){
      if(value === 'Mostrar Todos'){
        emitter.emit('eventCompanyId', '')
      } else {
        let compayId = this.companySite.filter(resp => resp.code === value)[0]
        emitter.emit('eventCompanyId', compayId.id)
      }
    }
  }


};
</script>

<style>

.navbar-scroll .v-navigation-drawer__content {
  overflow-y: hidden;
}
.navbar-scroll:hover .v-navigation-drawer__content {
  overflow-y: scroll;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.31);
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  margin-top: 5px;
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  transition: 5s;
}

.tooltip .tooltiptext {
  top: -5px;
  right: 105%;
}
/*
.v-menu__content--fixed {
    position: fixed;
    margin-top: 45px;
}*/

</style>
