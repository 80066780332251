
export default {
    
 
  data() {
    return {
      entityService: null,
    };
  },
  computed: {
   
    propertiesComp() {
      if (!this.schema || !this.schema.form || !this.schema.form.properties)
        return [];
      let propertieNames = Object.getOwnPropertyNames(
        this.schema.form.properties
      );

      if (propertieNames.length == 0) return [];
      propertieNames.splice(
        propertieNames.indexOf(propertieNames[propertieNames.length]),
        1
      );
      let properties = propertieNames.map((pn) => {
        return {
          name: pn,
          form: this.schema.form.properties[pn],
          schema: this.schema.properties[pn],
        };
      });
      properties.sort((a, b) => {
        if (a.form.order > b.form.order) return 1;
        else if (a.form.order < b.form.order) return -1;
        else return 0;
      });
      return properties;
    },
    tabGeneralComp() {
      if (!this.propertiesComp || this.propertiesComp.length == 0) return [];
      //let noTabs = this.propertiesComp.filter((p) => !p.form.tab);

      let tabGeneral = { tab: "general", properties: this.propertiesComp };

      let groups = tabGeneral.properties.map((p) => {
        return p.form.group;
      });

      if (groups.length > 0 && groups.filter((g) => g != null).length > 0) {
        groups = [...new Set(groups)];

        groups = groups.map((g) => {
          return {
            name: g,
            properties: tabGeneral.properties.filter((p) => p.form.group == g),
          };
        });

        tabGeneral.groups = groups;
      }

      tabGeneral.properties = tabGeneral.properties.filter(
        (p) => !p.form.group
      );

      return tabGeneral;
    },
    tabsComp() {
      if (!this.propertiesComp || this.propertiesComp.length == 0) return [];

      let propertiesTab = this.propertiesComp.filter((p) => p.form.tab);

      let tabs = propertiesTab.map((p) => {
        return p.form.tab;
      });

      if (tabs.length == 0) return [];

      tabs = [...new Set(tabs)];

      tabs = tabs.map((t) => {
        return {
          name: t,
          properties: this.propertiesComp.filter((p) => p.form.tab == t),
        };
      });

      tabs.forEach((t) => {
        let groups = t.properties.map((p) => {
          return p.form.group;
        });

        if (groups.length > 0 && groups.filter((g) => g != null).length > 0) {
          groups = [...new Set(groups)];

          groups = groups.map((g) => {
            return {
              name: g,
              properties: t.properties.filter((p) => p.form.group == g),
            };
          });

          t.groups = groups;
        }

        t.properties = t.properties.filter((p) => !p.form.group);
      });

      return tabs;
    },
  },
  methods: {
    
  },
  async mounted() {},
};
