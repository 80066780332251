<template>
    <v-dialog v-model="dialog" persistent max-width="900px">
        <v-card>
        <v-card-title class="text-h5">Tem certeza que deseja deletar esté grupo {{ groupName }} ?</v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete()">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm()">OK</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
         </v-card>
    </v-dialog>
</template>

<script>

export default {
    data(){
        return {
            dialog: false
        }
    },
    props: ["value", "groupName", "idGroup", "onClose"],
    watch: {
        value(v) {
            this.dialog = v;
      }
    },

    methods: {
        closeDelete(){
            this.dialog = false;

            this.$forceUpdate();
        },

        async deleteItemConfirm(){
            const data = await this.$acessGroup.removeGroup(this.groupName, this.idGroup);
            console.log(data);
        
            this.onClose();
            this.$forceUpdate();
            this.dialog = false;
        }
    }
}

</script>


<style>
.v-card-title{
    text-align: center;
}

</style>