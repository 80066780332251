<template>
  <v-dialog
    @click:outside="$emit('close')"
    v-model="dialog"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-app-bar flat color="transparent">
        <v-btn large icon @click="$emit('cancel')">
          <v-icon color="primary">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Alterar password do usuário '{{ name }}' ?</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-btn large icon color="success" @click="updatePassword()">
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container>
        <v-form ref="form">
            <v-text-field
            v-model="newPassword"
            label="Nova senha"
            type="password"
            :rules="requiredRule"
            ></v-text-field>
            <v-text-field
            v-model="confirmNewPassword"
            label="Confirmar nova senha"
            type="password"
            ></v-text-field>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
    props: ["value", "user"],
    name: "UpdatePasswordAdminDialog",
    
    watch: {
        value(v) {
            this.dialog = v;
            this.id = this.user.id;
            this.name = this.user.name;
            
            if (!v) {
              this.resetValidation();
            }
        },
    },

    data(){
        return {
            dialog: false,
            newPassword: "",
            confirmNewPassword: "",
            id: this.user.id,
            name: this.user.name,

            valid: true,
            requiredRule: [v => !!v || "Campo obrigatório!", v => v.length >= 6 || 
          'Precisa ter no minimo 6 caracteries', v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'A senha deve conter pelo menos uma letra minúscula, um número, um caractere especial @%$ e uma letra maiúscula',
          v => /(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#;$%*(){}_+^&]/.test(v) || "Faltou algum caraterie especial"]
        }
    },

    methods: {

        validate() {
            return this.$refs.form.validate();
        },

        resetValidation() {
          this.$refs.form.resetValidation();
        },

        async updatePassword(){
          if(this.newPassword == this.confirmNewPassword){
            if(this.validate()){
                await this.$auth.updatePasswordAdmin(this.id, this.newPassword);
                this.$notfy.success("Senha alterada");

                this.newPassword = "";
                this.dialog = false;
                //this.$emit("updated");
                window.location.reload()
            } else {
                this.$notfy.error("Algum campo não foi preenchido ou não foi preechido corretamente");
            }
          } else {
            this.$notfy.error("Senhas não conferem");
          }
        }
    }
}
</script>