<template>
  <div>
    <!--
    <v-autocomplete
      v-if="!schema"
      :items="[]"
      :multiple="type == 'string' ? false : true"
      hide-no-data
      hide-selected
      placeholder="..."
      prepend-icon="mdi-database-search"
      @blur="$emit('blur', $event)"
    >
      <template v-slot:selection="data">
        <template v-for="descriptor in descriptors ? descriptors : []">
          <span
            class="span"
            v-bind:key="descriptor"
            v-html="data.item[descriptor]"
          ></span>
        </template>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <template v-for="descriptor in descriptors ? descriptors : []">
              <span
                v-bind:key="descriptor"
                v-html="data.item[descriptor]"
              ></span>
            </template>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete> --->
    
    <v-autocomplete
    :loading="loading"
      v-model="model"
      :items="items"
      :search-input.sync="search"
      cache-items
      :multiple="type == 'string' ? false : true"
      @update:search-input="searchInput"
      hide-no-data
      hide-selected
      outlined
      :disabled="readyonly"
      item-value="id"
      :label="label"
      placeholder="..."
      prepend-icon="mdi-database-search"
      @input="inputValue"
      :filter="customFilter"
      clearable
      @click:prepend="handleAppendIconClick"
      @blur="$emit('blur', $event), enter(value, items)"
      @focus="focusInput"
      ref="relationInput"
    >

    <template v-slot:append-outer>
       <v-icon
         @click="openDialogPlus"
         color="blue"
         >mdi-plus</v-icon>
    </template>
      

      <template v-slot:selection="data">
        <template v-for="descriptor in descriptors ? descriptors : []">
          <span
            class="span"
            v-bind:key="data.item[descriptor]"
            v-html="data.item[descriptor]"
          ></span>
        </template>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <template v-for="descriptor in descriptors ? descriptors : []">
              <span
                @click="click(data.item)"
                v-bind:key="data.item[descriptor]"
                v-html="data.item[descriptor]"
              ></span>
            </template>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>

    <div style="margin-top: 15px;" v-if="schema">
      <relation-link-dialog
        :companySiteIds="companySiteIds"
        :searchFields="schema.searchFields"
        :schemaName="schemaName"
        :value="dialogEntity" 
        :relationCondition="relationCondition"
        :filterSchema="filterSchema"
        :filter="filter"
        :relationField="relationField"
        :property="property"
        :objFilter="objFilter"
        v-model="dialogEntity"
        @item="onRow"
        @OK="dialogEntity = false" 
      />
    </div>

    <div :key="key">
      <DialogPlus 
        v-if="formDialog"
        v-model="formDialog" 
        :schemaName="schemaName" 
        :schemaProp="schema" 
        :open="formDialog" 
        @close="eventPlusDialog" 
      />
    </div>
  </div>
</template>

<script>
import schemaService from "../../services/schemaService";
import EntityService from "../../services/entityService";
import RelationLinkDialog from "../entities/relationLinkDialog.vue";
import DialogPlus from "../entities/dialogPlus.vue";
export default {
  components: {  
    RelationLinkDialog,
    DialogPlus
  },
  props: [
    "multiCompany",
    "value",
    "schemaName",
    "label",
    "opened",
    "relationCondition",
    "fieldValues",
    "relationField",
    "field",
    "filter",
    "readyonly",
    "filterSchema",
    "property",
    "type",
    "readonlyCondition",
    "visibleCondition",
    "objFilter"
  ],
  data() {
    return {
      formDialog: false,
      dialogEntity: false,
      model: this.type == 'string' ? '' : [],
      entityService: new EntityService(this.schemaName),
      schema: null,
      items: [],
      search: null,
      descriptors: [],
      dataRelations: [],
      fieldSchema: '',
      relationF: '',
      key: 0,
      condition: '',
      companySiteIds: '',
      loading: true
    };
  },
  computed: {
    isRelationFilled() {
      //let fieldCondition = ''
      if (this.relationCondition !== undefined) {
       /* let conditionId = this.relationCondition?.condition.split('$')
        let conditionValue = this.relationCondition?.condition.split(',')
        
        if(conditionId.length == 2){
          fieldCondition = conditionId[1]
        } else {
          fieldCondition = conditionValue[1]
        } */

        const isFilled =
          this.fieldValues[this.relationCondition?.condition]?.trim().length >
          0;
        return isFilled;
      }

      return true;
    },
  },
  watch: {
    async opened() {
      localStorage.setItem(this.fieldSchema, '')
      this.model = null;
      (this.entityService = new EntityService(this.schemaName)),
        await this.getSchema();
      //await this.getItemById();
      this.descriptors = this.schema?.descriptor.split(",") || [];
    },
    isRelationFilled(isFilled) {
      if(!isFilled){
        this.$refs.relationInput.setValue(null);
        this.items = [];
      }
    },
  },

  methods: {
    openDialogPlus(){
      this.formDialog = true
      this.key += 1
    },
    eventPlusDialog(e){
      this.formDialog = e
    },
    handleAppendIconClick(){
      this.dialogEntity = true
    },
    onRow(item){
      if(this.type == 'string'){
        this.items = [ item ]
        this.model = item.id
      } 
      else if (this.type == 'array'){
        this.items.push(item)
        this.model.push(item.id)
      }  
      if(this.readonlyCondition){
        this.$emit('itemReadyonlyCondition', item[this.readonlyCondition.fieldSchema])
      } if(this.visibleCondition){
        this.$emit('visibleCondition', this.visibleCondition[item[this.visibleCondition.field]])
      }
      this.dialogEntity = false
      this.$emit("input", item.id);
    },
    customFilter(item) {
      return item[this.descriptors[0]];
    },
    async inputValue(value) {
      window.localStorage.setItem(`relationLink-${this.property}`, value)
      this.$emit("input", value);
    },

    async focusInput(){
      this.initializeItem()
    },

    async searchInput(value) {

      let delayTimer
        clearTimeout(delayTimer);
        delayTimer = setTimeout(async () => {

        
        this.$refs.relationInput.cachedItems = []
        if (!value) return;

        // RELATION FIELD
        if(localStorage.getItem(`relation-field-${this.schemaName}`) !== null){
          let relationFieldLocal = localStorage.getItem(`relation-field-${this.schemaName}`)
          let itemsLocal = relationFieldLocal.split(",").toString()
          if(itemsLocal){
            let res = await this.entityService.search(this.schema.searchFields, value, 1, 30, itemsLocal, null, this.companySiteIds)
            this.items = res?.data?.data?.data
          }
          return
        }

        if(this.objFilter != undefined){
          this.searchObjFilter(value, this.objFilter.schema, this.objFilter.filter, this.objFilter.field)
          return;
        }

        if(this.filter !== undefined){
          this.searchCondition(value)
        } 

        if(this.filterSchema !== undefined){
          //let filters = this.filterSchema.split(',')
          if(this.filterSchema.schemaFinal == this.schemaName){
            let filtro = this.$cookies.get("filterSchema");
            let entityService = new EntityService(this.filterSchema.schemaFinal.schemaContentIds)

            let req = await entityService.search(null, null, 1, 30, filtro)
            let items = req?.data?.data?.data

            let res = await this.entityService.searchAutoComplete(this.schema.searchFields, value, this.companySiteIds);
            this.items = res.data.data.data

            let ids = []
            for(let i = 0; i < items?.length; i++){
              ids.push(items[i]?.itemId)
            }
            this.items = this.items.filter(item => ids.indexOf(item.id) !== -1)
          } else {
            let res = await this.entityService.searchAutoComplete(this.schema.searchFields, value, this.companySiteIds);
            this.items = res.data.data.data
          }
        }

        else {
  /*  
          let res = await this.entityService.searchAutoComplete(null, value);
          if (res.data.success) {
            this.items = res.data.data.data;*/
            //console.log(this.items)

          // if(this.isRelationFilled == false) this.items = []
            if (this.relationCondition !== undefined) {
              let fieldCond = this.relationCondition?.field

              let conditionId = this.relationCondition?.condition.split('$')
              if(conditionId.length == 2){
                 let searchValue = `[${this.fieldValues[conditionId[1]]}],[${value}]`
                 let searchFields = `[${this.relationCondition.field}],[${this.schema.descriptor}]`
                let res = await this.entityService.searchAutoComplete(searchFields, searchValue, this.companySiteIds);
                this.items = res.data.data.data
                this.items = this.items.filter(
                  (result) => 
                    result[this.schema.descriptor].includes(value)
                  );
              } else {
                  let res = await this.entityService.searchAutoComplete(fieldCond, conditionId[0], this.companySiteIds);
                  this.items = res.data.data.data
                this.items = this.items.filter(
                  (result) =>
                    result[this.schema.descriptor].includes(value)
                  );
              }
/*
            if(this.relationF != ''){
              this.items = []
              const ids = localStorage.getItem(this.relationF)
              if(ids != ''){
                const resp = await this.entityService.getAll(1, 30, '', '', ids)
                this.items = resp.data.data.data
              } else {
                this.items = []
              }
            }*/
        } else {
          if(this.filterSchema === undefined && this.filter === undefined && localStorage.getItem(`relation-field-${this.schemaName}`) == null){
            let res = await this.entityService.searchAutoComplete(this.schema.searchFields, value, this.companySiteIds);
            this.items = res?.data?.data?.data
          }

        }
          this.$forceUpdate();
        }
      }, 1500)
    },
    async getItemById() {
      if (!this.value) return;

      if(this.type == "string"){
        let res = await this.entityService.getById(this.value);
        if (res.data.success) {
          this.items = [res.data.data];
          this.model = res.data.data;
          if(this.filterSchema !== undefined){
              //let filters = this.filterSchema.split(',')
              if(this.filterSchema.schemaInicial == this.schemaName){
                this.$cookies.set("filterSchema", this.items[0][this.filterSchema.ids]);
              }
          }
        }
      } else {
        for(let i = 0; i < this.value.length; i++){
          let res = await this.entityService.getById(this.value[i]);
          this.items.push(res.data.data);
          this.model.push(res.data.data);
        }
      }
    },

    async searchCondition(value) {
      let filter 
      if(value !== ''){
        filter = `${this.filter[1]},[${value}]`
      } else {
        filter = `${this.filter[1]},[]`
      }

      let res = await this.entityService.searchAutoComplete(`${this.filter[0]}`, `${filter}`, this.companySiteIds);
      
      if (res.data.success) {
        this.items = res.data.data.data;
        res.data.data.data.map((item) => {
          return item[this.field];
        });

        this.$forceUpdate();
      }
    },

    async searchObjFilter(value, arrayFilter, field){

      console.log(value, field, arrayFilter)

      let ids = []
      let schemaField = []
      for(let i = 0; i < arrayFilter.length; i++){
        
        let entityService = new EntityService(arrayFilter[i].schema);
        let res = await entityService.searchAutoComplete(
          arrayFilter[i].schemaFilter[0], arrayFilter[i].schemaFilter[1], this.companySiteIds);

        if(!res.data.success || res.data.data.data.length == 0) return;

        ids.push(res.data.data.data.map((item) => {
          return item['id'];
        }).join(','))

        schemaField.push(arrayFilter[i].schemaField)


      }
      
      let newValue = '[]'
        
      if(value != ''){
        newValue = `[${value}]`
      } else {
        newValue = '[]'
      }

      let newIds = ids.map((item) => {
        return `[${item}]`
      }).join(',')

      let newschemaField = schemaField.map((item) => {
        return `[${item}]`
      }).join(',')


      let res = await this.entityService.searchAutoComplete(`${newschemaField},${field}`, `${newIds},${newValue}`, this.companySiteIds);


      this.items = res.data.data.data;
    },

    async getItemByCondition() {
      if (this.relationCondition) {
        this.condition = this.relationCondition.condition;
        this.field = this.relationCondition.field;
      }
    },

    click(items) {
      //const jsonField = JSON.stringify(items[this.fieldSchema])
      if(this.fieldSchema != '') localStorage.setItem(this.fieldSchema, items[this.fieldSchema])
    },

    async enter(item, t){
      if(this.readonlyCondition){
        if(this.items?.filter(i => i?.id === item).length > 0){
          this.$emit('itemReadyonlyCondition', this.items?.filter(i => i.id === item)[0][this.readonlyCondition.fieldSchema])
        } else {
          this.$emit('itemReadyonlyCondition', '')
        }
      }
      if(this.visibleCondition){
        if(this.items?.filter(i => i.id === item).length > 0){
          this.$emit('visibleCondition', this.visibleCondition[this.items?.filter(i => i.id === item)[0][this.visibleCondition.field]])
        }
      }
      if(this.fieldSchema != ''){
        let res = await this.entityService.getAll(1, 10, '', '', item);
        let data = res?.data?.data?.data
        localStorage.setItem(this.fieldSchema, data[0][this.fieldSchema])
      } else if(this.filterSchema !== undefined){
        //let filters = this.filterSchema.split(',')
        if(this.filterSchema.schemaInicial == this.schemaName){
          let filter = t.filter(resp => resp.id === item)
          this.$cookies.set("filterSchema", filter[0][this.filterSchema.ids]);
        } 
      } else if(this.relationField){
        localStorage.setItem(`relation-field-${this.relationField.schema}`,  this.items.filter(i => i.id === item)[0][this.relationField.ids])
      }
    },

    async getSchema() {
      if (!this.schemaName) return;
      this.schema = await schemaService.getByNameToJS(this.schemaName);
    },

    async initializeItem(){
        // RELATION FIELD
        if(localStorage.getItem(`relation-field-${this.schemaName}`) !== null){
          let relationFieldLocal = localStorage.getItem(`relation-field-${this.schemaName}`)
          let itemsLocal = relationFieldLocal.split(",").toString()
          let res = await this.entityService.search(null, null, 1, 30, itemsLocal, null, this.companySiteIds)

          this.items = res?.data?.data?.data
          this.items = this.items.filter(item => itemsLocal.indexOf(item.id) !== -1)
          return
        }
        if(this.relationField && this.relationField.schema){
          localStorage.setItem(`relation-field-${this.relationField.schema}`,  this.items[0][this.relationField.ids])
        }
        // FIM
      

        if(this.filterSchema !== undefined){
          if(this.filterSchema.schemaFinal == this.schemaName){
            let filtro = this.$cookies.get("filterSchema");
            let entityService = new EntityService(this.filterSchema.schemaContentIds)

            let req = await entityService.search(null, null, 1, 30, filtro)
            let items = req.data.data.data

            let res = await this.entityService.searchAutoComplete(null, null, this.companySiteIds);
            this.items = res.data.data.data

            let ids = []
            for(let i = 0; i < items.length; i++){
              ids.push(items[i]?.itemId)
            }
            this.items = this.items.filter(item => ids.indexOf(item.id) !== -1)
          }
        }

        if(this.objFilter != undefined){
          console.log('objFilter ', this.objFilter)
          this.searchObjFilter('', this.objFilter.arrayFilter, this.objFilter.searchFields)
        }

      if(this.field != undefined){
        this.fieldSchema = this.field
        localStorage.setItem(this.fieldSchema, '')
      }

      if(this.filter !== undefined){
          this.searchCondition('');
        }

      if(this.relationField != undefined){
        this.relationF = this.relationField
      }

      if (this.relationCondition !== undefined) {
        let fieldCond = this.relationCondition?.field

        let conditionId = this.relationCondition?.condition.split('$')
              
        if(conditionId.length != 2){
          let res = await this.entityService.searchAutoComplete(fieldCond, conditionId[0], this.companySiteIds);

          this.items = res.data.data.data
        }

      } else {
        if(this.filterSchema === undefined && this.filter === undefined && this.objFilter == undefined){
          let res = await this.entityService.searchAutoComplete(null, null, this.companySiteIds);
          this.items = res?.data?.data?.data
        }
      }
    }
  },
  destroyed() {
    localStorage.removeItem(`relation-field-${this.relationField.schema}`)
  },
  async mounted() {
      try {
          (this.entityService = new EntityService(this.schemaName)),
          await this.getSchema();
        await this.getItemById();     
      } catch(err){
        console.log(err)
      }

      
      try {
        this.descriptors = this.schema?.descriptor.split(",") || [];
      } catch(err){
        //console.log(err);
      }

      // RELATION FIELD
      if(this.relationField != null & this.relationField.schema){
        localStorage.setItem(`relation-field-${this.relationField.schema}`, '')
      }
      
    if(this.value) this.initializeItem()

    if(this.schema.multi_company){
      let companyIds = []
      let dataUser = await this.$auth.getUserParams()
      companyIds.companySiteIds.split(",")
      companyIds.companySiteIds.push(dataUser.companySiteIdDefault)
      this.companySiteIds = companyIds.map((item) => {
        return item;
      }).join(",");
    }

    this.loading = false;

  },
};
</script>

<style scoped>
.span {
  margin-right: 10px;
  display: inline-block;
}
</style>
